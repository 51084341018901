import { Box, Typography } from '@mui/material';
import React from 'react';
import Box1 from '../../Atomos/Box';
import SatisfationMeter from '../../Atomos/SatisfationMeter';
import { scaleValue } from '../../../utils'; // Importando a função scaleValue

export type SatisfationMeterBoxProps = {
  title: string;
  angryEmoji: string;
  neutralEmoji: string;
  happyEmoji: string;
  percentage: number;
  scale?: number;
  boxStyle?: React.CSSProperties;
};

export default function SatisfationMeterBox({
  title,
  angryEmoji,
  neutralEmoji,
  happyEmoji,
  percentage,
  boxStyle,
  scale = 1,
}: SatisfationMeterBoxProps) {
  const textStyle: React.CSSProperties = {
    color: 'black',
    fontSize: scaleValue(scale, 16, 0.2), // Aplicando o scaleValue para fontSize
    fontFamily: 'Poppins',
    fontWeight: '700',
    letterSpacing: scaleValue(scale, 0.15), // Aplicando o scaleValue para letterSpacing
    wordWrap: 'break-word',
    width: '100%',
    textAlign: 'center',
  };

  const horizontalPadding = scaleValue(scale, 77, 1); // Aplicando o scaleValue para horizontalPadding
  const verticalPadding = scaleValue(scale, 4, 1); // Aplicando o scaleValue para verticalPadding

  return (
    <Box1 display='flex' flexDirection='column' sx={{ padding: `${verticalPadding}px ${horizontalPadding}px`, ...boxStyle }}>
      <Typography style={{ ...textStyle }}>{title}</Typography>
      <SatisfationMeter percentage={percentage} scale={scale} />
      <Box display='flex' width='100%' justifyContent='space-between'>
        <div
          style={{
            height: scaleValue(scale, '30px'), // Aplicando o scaleValue para height
            color: 'white',
            fontSize: scaleValue(scale, 24, 0.2), // Aplicando o scaleValue para fontSize
            fontFamily: 'Poppins',
            fontWeight: '700',
            letterSpacing: scaleValue(scale, 0.15), // Aplicando o scaleValue para letterSpacing
            wordWrap: 'break-word',
          }}
        >
          {angryEmoji}
        </div>
        <div
          style={{
            height: scaleValue(scale, '30px'),
            color: 'white',
            fontSize: scaleValue(scale, 24, 0.2),
            fontFamily: 'Poppins',
            fontWeight: '700',
            letterSpacing: scaleValue(scale, 0.15),
            wordWrap: 'break-word',
          }}
        >
          {neutralEmoji}
        </div>
        <div
          style={{
            height: scaleValue(scale, '30px'),
            color: 'white',
            fontSize: scaleValue(scale, 24, 0.2),
            fontFamily: 'Poppins',
            fontWeight: '700',
            letterSpacing: scaleValue(scale, 0.15),
            wordWrap: 'break-word',
          }}
        >
          {happyEmoji}
        </div>
      </Box>
    </Box1>
  );
}
