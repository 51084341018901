import { Button, ButtonProps } from '@mui/material';
import React from 'react';

interface IButton3Props {
  child: JSX.Element | string;
  sx?: React.CSSProperties;
}

const style: React.CSSProperties = {
  display: 'flex',
  width: '181px',
  padding: '7px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '9px',
  background: '#07FD72',
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '28px' /* 175% */,
  letterSpacing: '0.15px',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
};

export type Button3Props = ButtonProps & IButton3Props;

export default function Button3({ child, sx, ...props }: Button3Props) {
  return (
    <Button {...props} style={{ ...style, ...sx }}>
      {' '}
      {child}{' '}
    </Button>
  );
}
