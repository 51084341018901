import { Box, Grid, Typography, Card } from '@mui/material';
import { Formarters } from '../../../../lib/formaters';
import { IndicatorsDTO } from '../../../../dto';

export type ProductsSectionProps = {
  dataIndicator: IndicatorsDTO | undefined;
};

export default function ProductSection({ dataIndicator }: ProductsSectionProps): JSX.Element {
  return (
    <Box width='85%' mt={{ xs: 6, sm: 6, md: 4, lg: 4 }}>
      <Typography color='#00AB4A' fontSize='clamp(1.5rem, 2.5vw, 1.8rem)' fontFamily='Poppins' fontWeight={700}>
        Produtos
      </Typography>
      <Grid container spacing={2} pt={3}>
        {dataIndicator &&
          Object.entries(dataIndicator.produtos).map(([key, produto]) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={key}>
              <Card sx={{ width: '100%', backgroundColor: '#FAFAFA', borderRadius: '8px' }} variant='outlined'>
                <Box sx={{ padding: '35px 32px' }}>
                  <Typography fontSize='clamp(1.5rem, 2.5vw, 2rem)' fontFamily='Poppins' fontWeight={700}>
                    Produto {key}
                  </Typography>
                  {Object.entries(produto).map(([productKey, value]) => (
                    <Box display='flex' justifyContent='space-between' key={productKey} py={0.65}>
                      <Typography fontSize='15px' fontFamily='Poppins' fontWeight={400} color='#666666'>
                        {Formarters.translateKeyIndicators(productKey)}
                      </Typography>
                      <Typography fontSize='15px' fontFamily='Poppins' fontWeight={700} color='#666666'>
                        {Formarters.formatValuesIndicators(productKey, value)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
