import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, DialogActions, Button, DialogContentText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface AlertModalModalProps {
  modalIsOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function AlertModal({ modalIsOpen, onClose, onConfirm }: AlertModalModalProps): JSX.Element {
  return (
    <Dialog
      open={modalIsOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          overflow: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '425px',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          padding: '0rem 1.5rem',
          boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        },
      }}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <DialogTitle sx={{ flex: 1, textAlign: 'center', padding: '25px 0px 0px 0px' }}>
          <Typography variant='h6' component='div' sx={{ fontWeight: 'bold' }}>
            Deseja sair da simulação?
          </Typography>
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8, color: '#000000' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText sx={{ padding: 0, fontSize: '15px' }}>
          Ao confirmar, as alterações realizadas não serão salvas.
        </DialogContentText>
        <DialogActions sx={{ padding: '25px 0px 0px 0px' }}>
          <Button
            sx={{
              color: 'black',
              textTransform: 'none',
            }}
            onClick={onClose}
          >
            Cancelar
          </Button>

          <Button
            sx={{
              backgroundColor: '#d4d4d4',
              color: 'black',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#f4f4f4', // Tom mais claro no hover
              },
            }}
            onClick={onConfirm}
          >
            Confirmar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
