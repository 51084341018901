import { useQuery } from 'react-query';
import { IndicatorsDTO } from '../dto';

export const fetchIndicadorResults = async (jogo_id: string, numeroEtapa: number): Promise<IndicatorsDTO> => {
  const token = localStorage.getItem('token');

  const response = await fetch(
    `
  ${process.env.REACT_APP_BACKEND_URL}/jogos/${jogo_id}/indicadores?numero_etapa=${numeroEtapa}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch indicators results');
  }
  return response.json();
};

export function useIndicadorResults(jogo_id: string, numeroEtapa: number) {
  return useQuery({
    queryKey: ['IndicadorResults', jogo_id, numeroEtapa],
    queryFn: () => fetchIndicadorResults(jogo_id, numeroEtapa),
    staleTime: Infinity,
  });
}
