import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import { scaleValue } from '../../../lib/utils';
import { Box1, ArrowButton, JornalModal } from '../../../ui/index';
import { JornalDTO } from '../../../dto';

const decodeEscapedHTML = (str: string): string => {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
};

const stripHTMLTags = (str: string): string => {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
};

const decodeAndStripHTML = (str: string): string => {
  const decoded = decodeEscapedHTML(str);
  return stripHTMLTags(decoded);
};

type RightContainerProps = {
  boxStyle?: React.CSSProperties;
  scale?: number;
  onClickArrow?: () => void;
  jornais: JornalDTO[];
};

const openJournal = (path: string) => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  url.pathname = path;

  window.open(url.toString(), '_blank');
};

const BuildJornal = ({ jornal }: { jornal: JornalDTO }) => {
  const [isJornalOpen, setIsJornalOpen] = React.useState(false);

  return (
    <>
      <Box1
        display='flex'
        flexDirection='column'
        onClick={() => openJournal(`/news/${jornal.id}`)}
        sx={{ height: 'auto', width: 'auto', padding: '10px 8px', alignItems: 'center', cursor: 'zoom-in' }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            color: 'black',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          {jornal.materia_titulo}
        </Typography>
        <Typography
          sx={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            color: 'black',
            fontSize: '12px',
            fontFamily: 'Poppins',
            fontWeight: '400',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          {decodeAndStripHTML(jornal.materia_texto).length > 160
            ? `${decodeAndStripHTML(jornal.materia_texto).substring(0, 160)}...`
            : decodeAndStripHTML(jornal.materia_texto)}
        </Typography>
      </Box1>
      <JornalModal
        title={jornal.materia_titulo}
        content={decodeAndStripHTML(jornal.materia_texto)}
        modalIsOpen={isJornalOpen}
        onClose={() => setIsJornalOpen(false)}
      />
    </>
  );
};

function RightContainer({ jornais, boxStyle, scale, onClickArrow }: RightContainerProps): JSX.Element {
  scale = scale ?? 1;
  const minDesktopSize = useMediaQuery('(min-width: 1020px)');
  const mediumDesktopSize = useMediaQuery('(min-width: 1440px)');
  const largeDesktopSize = useMediaQuery('(min-width: 1920px)');

  let padding = '38px 24px';

  if (minDesktopSize) {
    padding = '2px 1px';
    scale = 0.8;
  }
  if (mediumDesktopSize) {
    padding = '16px 12px';
    scale = 1;
  }
  if (largeDesktopSize) {
    padding = '38px 24px';
    scale = 1;
  }

  const scaledBoxStyle: React.CSSProperties = {
    width: scaleValue(scale, 302 - 24 * 2),
    height: scaleValue(scale, 885 - 38 * 2),
    background: 'white',
    border: '1px #E0E0E0 solid',
    padding,
    alignItems: 'center',
    gap: '12px',
    position: 'relative',
    overflowY: 'scroll',
  };

  return (
    <>
      <Box display='flex' flexDirection='column' sx={{ ...scaledBoxStyle, ...boxStyle }}>
        <Typography
          sx={{ color: 'black', fontSize: '24px', fontFamily: 'Poppins', fontWeight: '700', letterSpacing: 0.15, wordWrap: 'break-word' }}
        >
          Jornal
        </Typography>
        <Box
          onClick={() => openJournal('/news')}
          color='#666666'
          padding='12px 7px'
          width='95%'
          bgcolor='#F3F3F3'
          borderRadius='12px'
          display='flex'
          alignItems='center'
          sx={{ fontSize: '15px', fontFamily: 'Poppins', fontWeight: 500, cursor: 'pointer' }}
        >
          <MarkunreadMailboxOutlinedIcon sx={{ width: '17px', height: '17px', marginRight: '10px' }} /> Acessar Jornal
        </Box>
        {jornais.map((jornal, index) => (
          <BuildJornal key={index} jornal={jornal} />
        ))}
      </Box>
      <ArrowButton direction='right' buttonStyle={{ position: 'absolute', left: '-9%', top: '45%' }} onClick={onClickArrow} />
    </>
  );
}

export default React.memo(RightContainer);
