import React, { createContext, useContext, useState } from 'react';
import { Tabs } from '../../Moleculas/Tabs';

export type TabContextType = {
  tabStorage: Record<string, any>;
  updateTabStorage: (key: string, value: any) => void;
};

const TabContext = createContext<TabContextType | undefined>(undefined);

export function useTabContext() {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error('useTabContext must be used within a TabContext.Provider');
  }
  return context;
}

export type TabTableProps = {
  tabs: {
    tabTitle: string;
    tabChild: React.ReactNode;
  }[];
  tabBoxStyle?: React.CSSProperties;
};

export const TabTable: React.FC<TabTableProps> = ({ tabs, tabBoxStyle }) => {
  const [tabStorage, setTabStorage] = useState<Record<string, any>>({});

  const updateTabStorage = (key: string, value: any) => {
    setTabStorage((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <TabContext.Provider value={{ tabStorage, updateTabStorage }}>
      <Tabs tabs={tabs} boxStyle={tabBoxStyle} />
    </TabContext.Provider>
  );
};
