import React from 'react';
import { ReactComponent as Pallet } from './Pallet.svg';
import { scaleValue } from '../../../utils'; // Importando a função scaleValue

export type SatisfationMeterProps = {
  percentage: number;
  scale?: number;
};

export default function SatisfationMeter({ percentage, scale = 1 }: SatisfationMeterProps): JSX.Element {
  if (scale < 0.5) scale = 0.5; // Limitando o valor mínimo de scale

  // This is based on the width of the bar
  const maxToRight: number = scaleValue(scale, 250, 0.4) as number; // Aplicando scaleValue
  const maxToLeft: number = scaleValue(scale, 0, 0.4) as number; // Aplicando scaleValue

  function calculatePosition(): string {
    const percentageToPixels: number = (percentage * 2.5) as number;
    if (percentageToPixels > maxToRight) return `${maxToRight.toString()}px`;
    if (percentageToPixels < maxToLeft) return `${maxToLeft.toString()}px`;
    return `${scaleValue(scale, percentageToPixels, 0.4).toString()}px`;
  }

  return (
    <div style={{ position: 'relative' }}>
      <Pallet style={{ position: 'absolute', top: scaleValue(scale, '-6px'), left: calculatePosition() }} />
      <div
        style={{
          width: scaleValue(scale, '284px', 0.4), // Aplicando scaleValue na largura
          height: scaleValue(scale, '20px'), // Aplicando scaleValue na altura
          background: 'linear-gradient(90deg, #FF0000 0%, #FFEC17 50%, #07FD72 100%)',
          borderRadius: scaleValue(scale, 9), // Aplicando scaleValue no borderRadius
          border: `${scaleValue(scale, 1)}px #E0E0E0 solid`, // Aplicando scaleValue na borda
        }}
      />
    </div>
  );
}
