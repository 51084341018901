import { TextField, TextFieldProps, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';

export type IInput1Props = {
  inputType?: string;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  defaultValue?: string;
  value?: string;
  isControlledComponent?: boolean;
};

export type Input1Props = TextFieldProps & IInput1Props;

const StyledTextField = styled(TextField)(({ theme }) => ({
  position: 'relative',
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#EEE', // Cor de fundo padrão
    transition: 'background-color 0.3s ease-in-out', // Transição suave para a cor de fundo
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: 0,
      width: '0%',
      height: '3px',
      backgroundColor: 'black',
      transition: 'width 0.3s ease-in-out',
      transform: 'translateX(-50%)',
    },
    '&:hover::after': {
      width: '100%',
    },
    '&.Mui-focused::after': {
      width: '100%',
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgb(215, 215, 215)', // Cor de fundo quando desabilitado
      '&::after': {
        width: '0%', // Desabilita a animação da borda inferior quando desativado
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none', // Remove a borda padrão
  },
}));

export default function Input1({
  inputType = 'text',
  isControlledComponent = true,
  value,
  error,
  helperText,
  disabled,
  defaultValue,
  ...props
}: Input1Props) {
  const [internalDefaultValue, setInternalDefaultValue] = useState<string | undefined>(defaultValue);

  useEffect(() => {
    if (disabled) setInternalDefaultValue(defaultValue);
  }, [defaultValue, disabled]);

  if (disabled) {
    return (
      <StyledTextField {...props} type={inputType} error={error} helperText={helperText} disabled={disabled} value={internalDefaultValue} />
    );
  }

  if (isControlledComponent) {
    return (
      <StyledTextField
        {...props}
        type={inputType}
        error={error}
        helperText={helperText}
        disabled={disabled}
        value={value ?? internalDefaultValue}
      />
    );
  }

  return (
    <StyledTextField
      {...props}
      type={inputType}
      error={error} // Permite que o campo de texto mostre o erro
      helperText={helperText} // Mostra o helperText abaixo do input
      disabled={disabled}
      defaultValue={defaultValue}
    />
  );
}
