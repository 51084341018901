import React from 'react';
import { Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/system';

// Componente ArrowButton com suporte para a prop "direction"
type Direction = 'up' | 'down' | 'left' | 'right';

interface ArrowButtonProps {
  direction: Direction;
  onClick?: () => void;
  buttonStyle?: React.CSSProperties;
}

const boxStyle: React.CSSProperties = {
  width: '42px',
  height: '42px',
  backgroundColor: '#00FF72', // Cor verde da seta
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
};

// Função auxiliar para rodar o ícone dependendo da direção
const getRotation = (direction: Direction) => {
  switch (direction) {
    case 'up':
      return '270deg';
    case 'down':
      return '90deg';
    case 'left':
      return '180deg';
    case 'right':
    default:
      return '0deg';
  }
};

const ArrowButton: React.FC<ArrowButtonProps> = ({ direction, onClick, buttonStyle }) => {
  return (
    <Box onClick={onClick} sx={{ ...boxStyle, ...buttonStyle }}>
      <ArrowForwardIosIcon
        sx={{
          transform: `rotate(${getRotation(direction)})`,
          color: 'black', // Cor da seta
        }}
      />
    </Box>
  );
};

export default ArrowButton;
