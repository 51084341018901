import { Box } from '@mui/material';
import React from 'react';
import { ProductStockContainer } from '../../../../ui/index';
import { FolhaDecisaoDTO } from '../../../../dto';
import { GameDetailsDTO } from '../../../../dto/gameDetailsDTO';

function MyStockContent({
  scale = 1,
  folhaDecisaoData,
  gameDetailsDTO,
}: {
  scale?: number;
  folhaDecisaoData: FolhaDecisaoDTO;
  gameDetailsDTO: GameDetailsDTO;
}) {
  const mappedSubProductNameByKey: Record<string, string> = {
    A: gameDetailsDTO.parametros_iniciais.parametros_produto.A.nome,
    B: gameDetailsDTO.parametros_iniciais.parametros_produto.B.nome,
    C: gameDetailsDTO.parametros_iniciais.parametros_produto.C.nome,
    D: gameDetailsDTO.parametros_iniciais.parametros_produto.D.nome,
    E: gameDetailsDTO.parametros_iniciais.parametros_produto.E.nome,
  };

  function createProductStockContainers() {
    return Object.keys(folhaDecisaoData.stock_values).map((key) => (
      <ProductStockContainer
        key={key}
        scale={scale}
        productName={`Produto ${key}`}
        subProductName={mappedSubProductNameByKey[key]}
        rowOne={{
          valueInputOne: folhaDecisaoData.stock_values[key].estoque_inicial,
          valueInputTwo: folhaDecisaoData.stock_values[key].estoque_entradas,
          valueInputThree: folhaDecisaoData.stock_values[key].estoque_saidas,
        }}
        rowTwo={{
          valueInputOne: folhaDecisaoData.stock_values[key].estoque_perdas,
          valueInputTwo: folhaDecisaoData.stock_values[key].estoque_final,
          valueInputThree: folhaDecisaoData.stock_values[key].estoque_em_transito,
        }}
        rowThree={{
          valueInputOne: folhaDecisaoData.stock_values[key].custo_medio,
          valueInputTwo: folhaDecisaoData.stock_values[key].saldo_final,
          valueInputThree: folhaDecisaoData.stock_values[key].qtd_nao_atendida,
        }}
        rowFour={{
          valueInputOne: folhaDecisaoData.stock_values[key].multa_nao_atendimento,
        }}
        stockQuantity={folhaDecisaoData.stock_values[key].estoque_final}
        onChange={function (data: any): void {
          throw new Error('Function not implemented.');
        }}
      />
    ));
  }

  return (
    <Box display='flex' flexDirection='column' gap='32px'>
      {createProductStockContainers()}
    </Box>
  );
}

export default React.memo(MyStockContent);
