/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState, useRef } from 'react';

export type TimeTrackingProps = {
  onEnd: () => void;
  beginTimeMinutes: number;
};

export default function TimeTracking({ onEnd, beginTimeMinutes }: TimeTrackingProps): JSX.Element {
  const [time, setTime] = useState(beginTimeMinutes * 60); // Tempo restante em segundos
  const endTime = useRef<number>(Date.now() + beginTimeMinutes * 60 * 1000); // Armazena o timestamp final

  useEffect(() => {
    // Atualiza o timestamp final sempre que `beginTimeMinutes` mudar
    endTime.current = Date.now() + beginTimeMinutes * 60 * 1000;
    setTime(beginTimeMinutes * 60); // Reinicia o estado do tempo
  }, [beginTimeMinutes]);

  useEffect(() => {
    // Função para atualizar o tempo restante
    const updateRemainingTime = () => {
      const now = Date.now(); // Obtém o timestamp atual
      const remainingTimeInSeconds = Math.max(0, Math.floor((endTime.current - now) / 1000)); // Calcula o tempo restante
      setTime(remainingTimeInSeconds);

      if (remainingTimeInSeconds <= 0) {
        clearInterval(interval); // Para o intervalo quando o tempo acabar
        onEnd(); // Chama o callback ao fim
      }
    };

    // Configura o intervalo para atualizar a cada segundo
    const interval = setInterval(updateRemainingTime, 1000);

    // Atualiza imediatamente para evitar atraso inicial
    updateRemainingTime();

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar
  }, [onEnd]);

  const formatTime = (seconds: number): string => {
    if (seconds <= 0) {
      return '00:00';
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.ceil(seconds % 60);

    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;

      if (hours >= 24) {
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;

        // if (days >= 30) {
        //     const months = Math.floor(days / 30);
        //     const remainingDays = days % 30;

        //     if (months >= 12) {
        //         const years = Math.floor(months / 12);
        //         const remainingMonths = months % 12;
        //         return `${years} ano${years > 1 ? 's' : ''} e ${remainingMonths} mês${remainingMonths > 1 ? 'es' : ''} e ${String(remainingDays).padStart(2, '0')} dia${remainingDays > 1 ? 's' : ''} e ${String(remainingHours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        //     }

        //     return `${months} mês${months > 1 ? 'es' : ''} e ${String(remainingDays).padStart(2, '0')} dia${remainingDays > 1 ? 's' : ''} e ${String(remainingHours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        // }

        return `${days} dia${days > 1 ? 's' : ''} e ${String(remainingHours).padStart(2, '0')}:${String(remainingMinutes).padStart(
          2,
          '0',
        )}:${String(remainingSeconds).padStart(2, '0')}`;
      }

      return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }

    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <div
      style={{
        width: 'auto',
        height: '100%',
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 1,
        paddingBottom: 1,
        background: '#07FD72',
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        display: 'inline-flex',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          color: 'black',
          fontSize: 15,
          fontFamily: 'Poppins',
          fontWeight: '700',
          letterSpacing: 0.15,
          wordWrap: 'break-word',
        }}
      >
        {formatTime(time)}
      </div>
    </div>
  );
}
