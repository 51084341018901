import { useQuery } from 'react-query';

const fetchTokenDownload = async (token: string): Promise<any> => {
  const response = await fetch(
    `
    ${process.env.REACT_APP_BACKEND_URL}/login/download_token`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch token download');
  }

  return response.json();
};

export const fetchRelatorioResults = async (jogo_id: string, type: string, etapa: string, isHtml: boolean = false): Promise<any> => {
  const token = localStorage.getItem('token'); // Pegando o token do localStorage
  const ktSecurityToken = await fetchTokenDownload(token ?? '');
  const id_empresas = localStorage.getItem('id_empresas');

  let numero_etapa_int = 0;
  numero_etapa_int = Number(etapa);

  const formatText = isHtml ? 'text/html' : 'pdf';

  const response = await fetch(
    `
  ${process.env.REACT_APP_BACKEND_URL}/jogos/${jogo_id}/relatorio?format=${formatText}&etapa=${numero_etapa_int}&empresa=${id_empresas}&relatorio=${type}&kt-security=${ktSecurityToken.token}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (isHtml) {
    const html = await response.text();
    return html;
  }

  if (!response.ok) {
    throw new Error('Failed to relatorio game results');
  }

  // Converte a resposta para um Blob
  const blob = await response.blob();

  // Cria uma URL para o Blob e salva no estado
  const pdfUrl = URL.createObjectURL(blob);

  return pdfUrl;
};

const fetchRelatorioInitialData = async (jogo_id: string): Promise<any> => {
  const token = localStorage.getItem('token'); // Pegando o token do localStorage
  const ktSecurityToken = await fetchTokenDownload(token ?? '');
  const numero_etapa = localStorage.getItem('numero_etapa');

  const response = await fetch(
    `
  ${process.env.REACT_APP_BACKEND_URL}/jogos/${jogo_id}/relatorio/dados_iniciais?etapa=${numero_etapa}&kt-security=${ktSecurityToken.token}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to relatorio game results');
  }

  // Converte a resposta para um Blob
  const blob = await response.blob();

  // Cria uma URL para o Blob e salva no estado
  const pdfUrl = URL.createObjectURL(blob);

  return pdfUrl;
};

export function useRelatorioResults(jogo_id: string, type: string, etapa: string, isHtml: boolean = false) {
  return useQuery({
    queryKey: ['relatorioResults', jogo_id, type],
    queryFn: () => fetchRelatorioResults(jogo_id, type, etapa, isHtml),
    retry: 1,
    staleTime: Infinity,
  });
}

export function useRelatorioInitialData(jogo_id: string) {
  return useQuery({
    queryKey: ['initialData', jogo_id],
    queryFn: () => fetchRelatorioInitialData(jogo_id),
    retry: 1,
    staleTime: Infinity,
  });
}
