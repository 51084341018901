export class Formarters {
  static formatMoney(value: number | string): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Formarters.parseNumberWithComma(value));
  }

  static parseNumberWithComma(n: any) {
    if (typeof n === 'string') {
      // Remove todos os separadores de milhares (pontos ou vírgulas)
      // e mantenha a vírgula ou ponto no final para separador decimal
      const cleaned = n.replace(/[^0-9,.]/g, ''); // Remove caracteres não numéricos (exceto ponto e vírgula)

      // Identifica qual é o separador decimal (último ponto ou vírgula)
      const lastCommaIndex = cleaned.lastIndexOf(',');
      const lastDotIndex = cleaned.lastIndexOf('.');
      const decimalSeparatorIndex = Math.max(lastCommaIndex, lastDotIndex);

      if (decimalSeparatorIndex !== -1) {
        // Divide a string em parte inteira e parte decimal
        const integerPart = cleaned.substring(0, decimalSeparatorIndex).replace(/[,.]/g, '');
        const decimalPart = cleaned.substring(decimalSeparatorIndex + 1);
        return Number(`${integerPart}.${decimalPart}`);
      }

      // Se não houver separador decimal, converte normalmente
      return Number(cleaned.replace(/[,.]/g, ''));
    }
    return Number(n);
  }

  static formatHelperText(value: number): string {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  static translateKeyIndicators = (key: string): string => {
    const translations: { [key: string]: string } = {
      nome: 'Nome',
      custo: 'Custo',
      demanda_etapa_anterior: 'Demanda Anterior',
      demanda_etapa_atual: 'Demanda Atual',
      demanda_etapa_proxima: 'Próxima Demanda',
      tx_financiamento: 'TX. Financiamento',
      juro_aplicacao_1: 'Aplicação 1',
      juro_aplicacao_2: 'Aplicação 2',
      juro_emprestimo_normal: 'Empréstimo normal',
      juro_emprestimo_especial: 'Empréstimo especial',
      juro_antecipacao_pagamento: 'Antecipação de pagamento',
      juro_desconto_duplicatas: 'Descontos duplicatas',
      multa_nao_atendimento_porc: 'Multa por não atendimento',
      vendedor: 'vendedores',
      operacional: 'operacionais',
      construcao: 'a construção',
      aluguel: 'o aluguel',
      tv: 'TV',
      impressos: 'Impressos',
      midias_sociais: 'Mídias Sociais',
      radio: 'Rádio',
    };

    return translations[key] || key;
  };

  static formatValuesIndicators = (key: string, value: any): string => {
    switch (key) {
      case 'nome':
        return `${value.toUpperCase()}`;
      case 'custo':
        return `$${Formarters.formatHelperText(value)}`;
      case 'tx_financiamento':
      case 'juro_aplicacao_1':
      case 'juro_aplicacao_2':
      case 'juro_emprestimo_normal':
      case 'juro_emprestimo_especial':
      case 'juro_antecipacao_pagamento':
      case 'juro_desconto_duplicatas':
      case 'multa_nao_atendimento_porc':
        return `${value}%`;
      default:
        return value;
    }
  };
}
