import React, { useState } from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';
import { ReactComponent as HelpIcon } from './HelpIcon.svg';

export type HelpTooltipProps = {
  tooltipText: string;
  size?: number;
};

const style: React.CSSProperties = {
  display: 'flex',
  width: '22px',
  height: '22px',
  background: '#000',
  padding: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100px',
  cursor: 'pointer',
};

export const HelpTooltip: React.FC<HelpTooltipProps> = ({ tooltipText, size }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip open={open} onClose={handleTooltipClose} title={tooltipText} onClick={handleTooltipOpen} placement='top' arrow>
      <Box display='flex' alignItems='center' sx={{ ...style, width: size || '22px', height: size || '22px' }}>
        <IconButton onClick={handleTooltipOpen} sx={{ padding: 0, color: 'white', width: size || '22px', height: size || '22px' }}>
          <HelpIcon style={{ fontSize: size || 22, height: '14px', width: '9px' }} />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
