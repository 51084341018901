import { Button, ButtonProps, Typography } from '@mui/material';
import React from 'react';

interface IButton1Props {
  text: string;
  icon: React.JSX.Element;
  isActive: boolean;
  sx?: React.CSSProperties;
}

const activestyle: React.CSSProperties = {
  display: 'flex',
  width: 'auto',
  padding: '7px 12px',
  height: '42px',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '9px',
  background: '#F3F3F3',
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '28px' /* 186.667% */,
  letterSpacing: '0.15px',
  textTransform: 'none',
  justifyContent: 'start',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
};

const deactiveStyle: React.CSSProperties = {
  color: '#666',
  fontFamily: 'Poppins',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '28px' /* 186.667% */,
  letterSpacing: '0.15px',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
};

const textStyle: React.CSSProperties = {
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '28px' /* 186.667% */,
  letterSpacing: '0.15px',
  textTransform: 'none',
};

export type Button1Props = ButtonProps & IButton1Props;

export default function Button1({ text, icon, isActive, sx, ...props }: Button1Props) {
  return (
    <Button {...props} style={{ ...activestyle, ...sx, color: isActive ? '#000' : '#666' }}>
      {icon}
      <Typography sx={isActive ? { ...textStyle } : { ...deactiveStyle }}>{text}</Typography>
    </Button>
  );
}
