import { Box, Typography, SxProps } from '@mui/material';
import React from 'react';
import { scaleValue } from '../../../utils';
import Box1 from '../../Atomos/Box';
import { ArrowButton } from '../../Atomos';

type RightContainerProps = {
  boxStyle?: React.CSSProperties;
};

const scale = 1;

const scaledBoxStyle: React.CSSProperties = {
  width: scaleValue(scale, 302 - 24 * 2),
  height: scaleValue(scale, 885 - 38 * 2),
  background: 'white',
  border: '1px #E0E0E0 solid',
  padding: `${scaleValue(scale, 38)}px ${scaleValue(scale, 24)}px`,
  alignItems: 'center',
  gap: '12px',
  position: 'relative',
};

export default function RightContainer({ boxStyle }: RightContainerProps): JSX.Element {
  return (
    <Box display='flex' flexDirection='column' sx={{ ...scaledBoxStyle, ...boxStyle }}>
      <Typography
        sx={{ color: 'black', fontSize: '24px', fontFamily: 'Poppins', fontWeight: '700', letterSpacing: 0.15, wordWrap: 'break-word' }}
      >
        Jornal
      </Typography>
      <Box1 display='flex' flexDirection='column' sx={{ height: 'auto', width: 'auto', padding: '4px', alignItems: 'center' }}>
        <Typography
          sx={{
            textAlign: 'center',
            color: 'black',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          Noticia 1
        </Typography>
        <Typography
          sx={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            color: 'black',
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: '400',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          Morem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing.
        </Typography>
      </Box1>
      <Box1 display='flex' flexDirection='column' sx={{ height: 'auto', width: 'auto', padding: '4px', alignItems: 'center' }}>
        <Typography
          sx={{
            textAlign: 'center',
            color: 'black',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          Noticia 2
        </Typography>
        <Typography
          sx={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            color: 'black',
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: '400',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          Morem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing.
        </Typography>
      </Box1>
      <Box1 display='flex' flexDirection='column' sx={{ height: 'auto', width: 'auto', padding: '4px', alignItems: 'center' }}>
        <Typography
          sx={{
            textAlign: 'center',
            color: 'black',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          Noticia 3
        </Typography>
        <Typography
          sx={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            color: 'black',
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: '400',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          Morem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing.
        </Typography>
      </Box1>
      <Box1 display='flex' flexDirection='column' sx={{ height: 'auto', width: 'auto', padding: '4px', alignItems: 'center' }}>
        <Typography
          sx={{
            textAlign: 'center',
            color: 'black',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          Noticia 4
        </Typography>
        <Typography
          sx={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            color: 'black',
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: '400',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          Morem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing.
        </Typography>
      </Box1>
      <ArrowButton direction='left' buttonStyle={{ position: 'absolute', left: '-7%', top: '45%' }} />
    </Box>
  );
}
