import React from 'react';
import { Box, Typography } from '@mui/material';

export type IconCardProps = {
  icon?: React.ReactNode;
  label: string;
  isSelected: boolean;
  onClick: () => void;
};

const boxStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 3px',
  borderRadius: '8px',
  border: `2px solid #E0E0E0`,
  width: '100px',
  height: '85px',
  transition: 'border-color 0.3s',
  cursor: 'pointer',
  fontWeight: 'bold',
};

const textCss: React.CSSProperties = {
  overflowWrap: 'anywhere',
  textAlign: 'center',
};

export const IconCard: React.FC<IconCardProps> = ({ icon, label, isSelected, onClick }) => {
  return (
    <Box sx={{ ...boxStyle, borderColor: isSelected ? '#07FD72' : '#E0E0E0' }} onClick={onClick}>
      <Box sx={{ mb: 1 }}>{icon}</Box>
      <Typography variant='body2' sx={{ ...textCss }}>
        {label}
      </Typography>
    </Box>
  );
};
