import React from 'react';
import { Box } from '@mui/material';

export type TabContentProps = {
  children?: React.ReactNode;
  value: number;
  index: number;
};

export const TabContent: React.FC<TabContentProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role='menuitem'
      hidden={value !== index}
      id={`TabContent-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ border: '1.5px solid #E0E0E0', background: 'white', height: '100%', overflowY: 'scroll', overflowX: 'hidden' }} // 200px padding original
      {...other}
    >
      {value === index && (
        <Box padding='24px 24px 72px 24px' width='100%'>
          {children}
        </Box>
      )}
    </div>
  );
};
