import { Box } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import React, { useState } from 'react';
import Button1 from '../../Atomos/Button1';

export type NavigationButtonsProps = {
  onChange: (value: string) => void;
  boxStyle?: React.CSSProperties;
};

// No momento MyCompanies deverá sempre ser marcado como o ativo.
export default function NavigationButtons({ onChange, boxStyle }: NavigationButtonsProps): JSX.Element {
  const [activeButton, setActiveButton] = useState<string>('MyCompanies');

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
    onChange(buttonName); // Propagate the change up to the parent component
  };

  return (
    <Box display='flex' flexDirection='column' gap='12px' sx={{ ...boxStyle }}>
      <Button1
        text='Home'
        icon={<HomeOutlinedIcon sx={{ fontSize: '20px' }} />}
        isActive
        onClick={() => handleButtonClick('MyCompanies')}
      />
      <Button1
        text='Manual'
        icon={<CollectionsBookmarkOutlinedIcon sx={{ fontSize: '20px' }} />}
        isActive={false}
        onClick={() => handleButtonClick('Tutorial')}
      />
      {/* <Button1
        text='Doc. Dados Iniciais'
        icon={<ReceiptLongOutlinedIcon sx={{ fontSize: '20px' }} />}
        isActive={false}
        onClick={() => handleButtonClick('InitialData')}
      /> */}
      <Button1
        text='Indicadores'
        icon={<LeaderboardOutlinedIcon sx={{ fontSize: '20px' }} />}
        isActive={false}
        onClick={() => handleButtonClick('Indicators')}
      />
      {/* <Button1 text='Tutoriais' icon={<CollectionsBookmarkOutlinedIcon sx={{fontSize: '20px'}} />} isActive={activeButton === 'Tutorial'} onClick={() => handleButtonClick('Tutorial')} />
            <Button1 text='Suporte' icon={<HeadsetMicOutlinedIcon sx={{fontSize: '20px'}} />} isActive={activeButton === 'Support'} onClick={() => handleButtonClick('Support')} /> */}
    </Box>
  );
}
