import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Tab } from '../../Atomos/Tab';
import { TabContent } from '../TabContent';

export type TabProps = {
  tabTitle: string;
  tabChild: React.ReactNode;
};

export type TabsProps = {
  tabs: TabProps[];
  boxStyle?: React.CSSProperties;
};

export const Tabs: React.FC<TabsProps> = ({ tabs, boxStyle }) => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ height: '100%', ...boxStyle }}>
      <Box display='flex' alignItems='flex-end'>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.tabTitle} onChange={(event) => handleChange(event, index)} value={index} isActive={index === value} />
        ))}
      </Box>
      {tabs.map((tab, index) => (
        <TabContent key={index} value={value} index={index}>
          {tab.tabChild}
        </TabContent>
      ))}
    </Box>
  );
};
