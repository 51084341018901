import { Validadores, FolhaDecisaoDTO } from '../../../../dto';
import { EtapaDTO } from '../../../../dto/etapaDTO';
import { getDataFromLocalStorageOrFallback } from '../../../../lib/utils';

export const searchCustPriceByPaymentTerm = (compras: any, dataEtapa: EtapaDTO, key: string) => {
  if (compras[key]?.prazo_pagamento === 1) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa;
  }
  if (compras[key]?.prazo_pagamento === 2) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas;
  }
  if (compras[key]?.prazo_pagamento === 3) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas;
  }
  if (compras[key]?.prazo_pagamento === 20) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas_sem_entrada;
  }
  if (compras[key]?.prazo_pagamento === 30) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas_sem_entrada;
  }
  return 0;
};

export const searchMinimumPriceByPaymentTerm = (compras: any, dataEtapa: EtapaDTO, key: string) => {
  if (compras[key]?.prazo_pagamento === 1) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa;
  }
  if (compras[key]?.prazo_pagamento === 2) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas;
  }
  if (compras[key]?.prazo_pagamento === 3) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas;
  }
  if (compras[key]?.prazo_pagamento === 20) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas_sem_entrada;
  }
  if (compras[key]?.prazo_pagamento === 30) {
    return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas_sem_entrada;
  }
  return 0;
};

export function getSellPrice(
  validadores: Validadores,
  folhaDecisaoData: FolhaDecisaoDTO,
  key: string,
  compras: any,
  vendas: any,
  dataEtapa: EtapaDTO,
): number {
  if ((compras[key].prazo_pagamento !== 0 || compras[key].prazo_pagamento === 0) && vendas[key].condicao_recebimento === 0) {
    return 0;
  }
  // if(vendas[key].preco_venda > validadores.produtos[key].preco_venda_minimo) return vendas[key].preco_venda
  if (validadores.produtos[key].preco_venda_minimo !== 0) return validadores.produtos[key].preco_venda_minimo;
  // if (folhaDecisaoData.compras[key].preco_custo !== 0) return folhaDecisaoData.compras[key].preco_custo;

  return searchMinimumPriceByPaymentTerm(compras, dataEtapa, key);
}

export const getDatasFromLocalStorageOrFallback = (storageData: any, fallbackData: any) => {
  const { compras } = getDataFromLocalStorageOrFallback<any>(storageData, fallbackData, [
    'compras.A.prazo_pagamento',
    'compras.A.preco_custo',
    'compras.A.qtde_compra',
    'compras.B.prazo_pagamento',
    'compras.B.preco_custo',
    'compras.B.qtde_compra',
    'compras.C.prazo_pagamento',
    'compras.C.preco_custo',
    'compras.C.qtde_compra',
    'compras.D.prazo_pagamento',
    'compras.D.preco_custo',
    'compras.D.qtde_compra',
    'compras.E.prazo_pagamento',
    'compras.E.preco_custo',
    'compras.E.qtde_compra',
  ]);

  const { vendas } = getDataFromLocalStorageOrFallback<any>(storageData, fallbackData, [
    'vendas.A.condicao_recebimento',
    'vendas.A.preco_venda',
    'vendas.A.tx_financiamento_vendedor_porc',
    'vendas.A.com_entrada',
    'vendas.B.condicao_recebimento',
    'vendas.B.preco_venda',
    'vendas.B.tx_financiamento_vendedor_porc',
    'vendas.B.com_entrada',
    'vendas.C.condicao_recebimento',
    'vendas.C.preco_venda',
    'vendas.C.tx_financiamento_vendedor_porc',
    'vendas.C.com_entrada',
    'vendas.D.condicao_recebimento',
    'vendas.D.preco_venda',
    'vendas.D.tx_financiamento_vendedor_porc',
    'vendas.D.com_entrada',
    'vendas.E.condicao_recebimento',
    'vendas.E.preco_venda',
    'vendas.E.tx_financiamento_vendedor_porc',
    'vendas.E.com_entrada',
  ]);

  const { financas } = getDataFromLocalStorageOrFallback<any>(storageData, fallbackData, [
    'financas.aplicacao_1.deposito',
    'financas.aplicacao_1.saque',
    'financas.aplicacao_2.deposito',
    'financas.aplicacao_2.prazo',
    'financas.emprestimo_normal.valor',
    'financas.emprestimo_normal.prazo',
  ]);

  const { contas_pagar } = getDataFromLocalStorageOrFallback<any>(storageData, fallbackData, [
    'contas_pagar.desconto_duplicatas',
    'contas_pagar.antecipacao_pgtos',
    'contas_pagar.ampliacao_m2',
    'contas_pagar.diversos',
  ]);

  const { rh } = getDataFromLocalStorageOrFallback<any>(storageData, fallbackData, [
    'rh.operacionais.admitidos',
    'rh.vendedores.admitidos',
    'rh.operacionais.demitidos',
    'rh.vendedores.demitidos',
    'rh.operacionais.salarios',
    'rh.vendedores.salarios',
    'rh.vendedores.comissao',
    'rh.vendedores.hora_extra',
    'rh.operacionais.hora_extra',
    'rh.vendedores.treinamento',
    'rh.operacionais.treinamento',
    'rh.operacionais.participacao',
  ]);

  const { marketing } = getDataFromLocalStorageOrFallback<any>(storageData, fallbackData, [
    'marketing.A.tv',
    'marketing.A.midias_sociais',
    'marketing.A.radio',
    'marketing.A.impressos',
    'marketing.B.tv',
    'marketing.B.midias_sociais',
    'marketing.B.radio',
    'marketing.B.impressos',
    'marketing.C.tv',
    'marketing.C.midias_sociais',
    'marketing.C.radio',
    'marketing.C.impressos',
    'marketing.D.tv',
    'marketing.D.midias_sociais',
    'marketing.D.radio',
    'marketing.D.impressos',
    'marketing.E.tv',
    'marketing.E.midias_sociais',
    'marketing.E.radio',
    'marketing.E.impressos',
  ]);

  return { compras, vendas, financas, contas_pagar, rh, marketing };
};
