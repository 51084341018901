import React from 'react';

const ErrorPage: React.FC = () => {
  return (
    <div>
      <h1>Erro ao fazer login</h1>
      <p>Ocorreu um erro ao tentar autenticar. Por favor, tente novamente.</p>
    </div>
  );
};

export default ErrorPage;
