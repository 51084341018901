import { useQuery } from 'react-query';
import { JornalDTO } from '../dto';

export const fetchJornalResults = async (jogo_id: string, numeroEtapa: number): Promise<JornalDTO[]> => {
  const token = localStorage.getItem('token'); // Pegando o token do localStorage

  // jogos/${jogo_id}/folha_decisao
  const response = await fetch(
    `
  ${process.env.REACT_APP_BACKEND_URL}/jogos/${jogo_id}/noticias?numero_etapa=${numeroEtapa}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch jornal results');
  }
  return response.json();
};

export const fetchJournalByIdResults = async (jogo_id: string, id?: string): Promise<JornalDTO> => {
  const token = localStorage.getItem('token');

  const response = await fetch(
    `
  ${process.env.REACT_APP_BACKEND_URL}/jogos/${jogo_id}/noticias/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch jornal results');
  }
  return response.json();
};

export function useJornalResults(jogo_id: string, numeroEtapa: number) {
  return useQuery({
    queryKey: ['jornalResults', jogo_id, numeroEtapa],
    queryFn: () => fetchJornalResults(jogo_id, numeroEtapa),
    staleTime: Infinity,
  });
}

export function useJornalByIdResults(jogo_id: string, id?: string) {
  return useQuery({
    queryKey: ['journalByIdResults', jogo_id, id],
    queryFn: () => fetchJournalByIdResults(jogo_id, id),
    staleTime: Infinity,
  });
}
