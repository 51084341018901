import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; // Ícone de erro

export interface Error {
  msg: string;
  param: string;
  location: string;
}

type ParsedError = {
  section: string;
  subSection?: string;
  field: string;
  errorMsg: string;
};

export interface ErrorModalProps {
  modalIsOpen: boolean;
  onClose: () => void;
  errorCode: number; // Código do erro
  errorMessage: string; // Exibe a mensagem principal do erro
  errors?: Error[]; // Lista de erros adicionais (opcional)
}

export default function ErrorModal({ errorCode, modalIsOpen, onClose, errorMessage, errors }: ErrorModalProps): JSX.Element {
  const title = errorCode === 422 ? 'Atenção' : 'Erro';
  const mainColor = errorCode === 422 ? 'orange' : 'red';
  const mainErrorMessage = errorCode === 422 ? 'Quase lá! Parece que alguns campos precisam de uma pequena revisão.' : errorMessage;
  const subErrorMessage = errorCode === 422 ? 'Campos a revisar:' : 'Detalhes do erro:';

  function parseErrorMessage(error: Error): ParsedError {
    const { msg, param } = error;

    const paramParts = param.split('.');

    const section = paramParts[1];
    const subSection = paramParts.length > 3 ? paramParts[2] : undefined;
    const field = paramParts.length > 3 ? paramParts[3] : paramParts[2];

    const errorMsg = msg.replace(`${param} `, '');

    return {
      section,
      subSection,
      field,
      errorMsg,
    };
  }

  return (
    <Dialog
      open={modalIsOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          overflow: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'fit-content',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          padding: '2rem 1.5rem',
          boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fundo escuro com transparência
        },
      }}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <DialogTitle sx={{ flex: 1, textAlign: 'center', padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ErrorOutlineIcon sx={{ fontSize: 32, color: mainColor, marginRight: '8px' }} />
          <Typography variant='h6' component='div' sx={{ fontWeight: 'bold', color: mainColor }}>
            {title}
          </Typography>
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8, color: '#000000' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ textAlign: 'center', paddingTop: '16px' }}>
        {/* Exibe a mensagem principal do erro */}
        <Typography variant='body1' sx={{ marginBottom: '16px', fontSize: '16px', fontWeight: '500' }}>
          {mainErrorMessage}
        </Typography>

        {/* Exibe a lista de erros, se houver */}
        {errors && errors.length > 0 && (
          <Box sx={{ textAlign: 'left', marginTop: '24px' }}>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
              {subErrorMessage}
            </Typography>
            <List sx={{ paddingLeft: '16px' }}>
              {errors.map((error, index) => (
                <ListItem key={index} sx={{ paddingBottom: '8px' }}>
                  {error.param ? (
                    <Box>
                      Seção: {parseErrorMessage(error).section}
                      <br />
                      {parseErrorMessage(error).subSection
                        ? `${
                            parseErrorMessage(error).section === 'rh' || parseErrorMessage(error).section === 'financas'
                              ? 'Tipo: '
                              : 'Produto: '
                          }${parseErrorMessage(error).subSection}`
                        : null}
                      {parseErrorMessage(error).subSection ? <br /> : null}
                      Campo: {parseErrorMessage(error).field}
                      <br />
                      Descrição: {parseErrorMessage(error).errorMsg}
                    </Box>
                  ) : (
                    error.msg
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
