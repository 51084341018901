import React from 'react';
import Box1 from '../../Atomos/Box';
import InputWithTitle2 from '../../Moleculas/InputWithTitle2';
import { scaleValue } from '../../../utils'; // Função scaleValue

export type RowInputProp = {
  key: string;
  value: string;
  disabled?: boolean;
  helperText?: string;
  validator?: (value: string) => string | null;
};

export type Row = {
  valueInputOne?: RowInputProp;
};

export type InvoiceContainerProps = {
  rowOne: Row;
  rowTwo: Row;
  rowThree: Row;
  rowFour?: Row;
  onChange: (data: any, error: string | null) => void;
  scale?: number; // Adicionando a prop de escala
};

export default function InvoiceContainer({ rowOne, rowTwo, rowThree, rowFour, onChange, scale = 1 }: InvoiceContainerProps) {
  const values: any = {};

  const handleChangeInput = (key: string, value: string, error: string | null) => {
    values[key] = value;
    onChange(values, error);
  };

  return (
    <Box1
      display='flex'
      flexDirection='column'
      sx={{
        height: 'auto',
        padding: `${scaleValue(scale, '23px')} ${scaleValue(scale, '40px')}`,
        minWidth: scaleValue(scale, '829px'),
        gap: scaleValue(scale, '28px'),
        width: '95%',
        boxSizing: 'border-box',
      }}
    >
      <InputWithTitle2
        title='Desconto de Títulos($)'
        tooltipText='Valor do contas a receber na etapa X+1 que poderá ser descontado. O banco repassa o valor, descontado dos juros.'
        initialAdornment='$'
        disabled={(rowOne.valueInputOne as RowInputProp).disabled}
        validator={(rowOne.valueInputOne as RowInputProp).validator}
        helperText={(rowOne.valueInputOne as RowInputProp).helperText}
        onChange={(event, error) => handleChangeInput((rowOne.valueInputOne as RowInputProp).key, event.target.value, error)}
        defaultValue={(rowOne.valueInputOne as RowInputProp).value}
        scale={scale}
      />
      <InputWithTitle2
        title='Antecipação de Pagamentos($)'
        tooltipText='Possibilidade de antecipar pagamentos dos fornecedores na etapa X+1.'
        initialAdornment='$'
        disabled={(rowTwo.valueInputOne as RowInputProp).disabled}
        validator={(rowTwo.valueInputOne as RowInputProp).validator}
        helperText={(rowTwo.valueInputOne as RowInputProp).helperText}
        onChange={(event, error) => handleChangeInput((rowTwo.valueInputOne as RowInputProp).key, event.target.value, error)}
        defaultValue={(rowTwo.valueInputOne as RowInputProp).value}
        scale={scale}
      />
      <InputWithTitle2
        title='Ampliação M2'
        tooltipText='Aumento, em m2, das instalações físicas da empresa.'
        initialAdornment='$'
        disabled={(rowThree.valueInputOne as RowInputProp).disabled}
        validator={(rowThree.valueInputOne as RowInputProp).validator}
        helperText={(rowThree.valueInputOne as RowInputProp).helperText}
        onChange={(event, error) => handleChangeInput((rowThree.valueInputOne as RowInputProp).key, event.target.value, error)}
        defaultValue={(rowThree.valueInputOne as RowInputProp).value}
        scale={scale}
      />
      <InputWithTitle2
        title='Diversos'
        tooltipText='Valor disponibilizado pelo sistema. Utilizado para inserção de bônus ou multas.'
        initialAdornment='$'
        disabled={(rowFour?.valueInputOne as RowInputProp).disabled}
        validator={(rowFour?.valueInputOne as RowInputProp).validator}
        helperText={(rowFour?.valueInputOne as RowInputProp).helperText}
        onChange={(event, error) => handleChangeInput((rowFour?.valueInputOne as RowInputProp).key, event.target.value, error)}
        defaultValue={(rowFour?.valueInputOne as RowInputProp).value}
        scale={scale}
      />
    </Box1>
  );
}
