import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box1, Input1, HelpTooltip, DropdownSelect } from '../../../index';
import { scaleValue, Formarters } from '../../../utils';

export type DropdownSelectOption = {
  label: string;
  value: string;
  key?: string;
  defaultValue?: DropdownSelectOption;
};

export type RowInputProp = {
  key: string;
  value: string;
  helperText?: string;
  disabled?: boolean;
  validator?: (value: string) => string | null;
};

export type Row = {
  valueInputOne?: RowInputProp | DropdownSelectOption[];
  valueInputTwo?: RowInputProp | DropdownSelectOption[];
};

export type RhContainerProps = {
  rowOne: Row;
  rowTwo: Row;
  rowThree: Row;
  rowFour: Row;
  rowFive: Row;
  rowSix: Row;
  rowSeven: Row;
  isTimeOver?: boolean;
  onChange: (data: any, error: string | null) => void;
  scale?: number; // Adicionando a prop scale
};

const operatorHelpText: Record<string, string> = {
  admitidos: 'Operacionais: aumento do número de operacionais. Auxilia no MRH-CO.',
  demitidos: 'Operacionais: redução da força de trabalho dos operacionais.',
  salario: 'Operacionais: define-se o salário dos operacionais.',
  horaExtra: 'Operacionais: define-se o valor entre 0 e 25%.',
  treinamento:
    'Operacionais: define-se o valor entre 0 e 9. Cada investimento em treinamento, por exemplo, 3, será aplicado um percentual de 30% sobre os salários dos respectivos colaboradores.',
  participacao: 'Operacionais: participação nos lucros após os impostos, de 0 a 9.',
};

const salerHelpText: Record<string, string> = {
  admitidos: 'Vendedores: aumento do número de vendedores. Auxilia no MRH-CV.',
  demitidos: 'Vendedores: redução da força de trabalho dos vendedores.',
  salario: 'Vendedores: define-se o salário dos vendedores.',
  horaExtra: 'Vendedores: define-se o valor entre 0 e 25%.',
  treinamento:
    'Vendedores: define-se o valor entre 0 e 9. Cada investimento em treinamento, por exemplo, 3, será aplicado um percentual de 30% sobre os salários dos respectivos colaboradores.',
  comissao: 'Vendedores: indica o percentual (%) sobre as vendas brutas (receita bruta), pago aos vendedores.',
};

const textStyle: any = (scale: number) => ({
  color: 'black',
  fontSize: scaleValue(scale, 22),
  fontFamily: 'Poppins',
  fontWeight: '700',
  letterSpacing: 0.15,
  wordWrap: 'break-word',
});

function RowContainer({
  title,
  hasOperators = true,
  hasSales = true,
  rowValue,
  onChangeInputOne,
  onChangeInputTwo,
  tooltipHelpTextKey = '',
  scale = 1,
}: {
  title: string;
  hasOperators?: boolean;
  hasSales?: boolean;
  rowValue: Row;
  onChangeInputOne: (event: React.ChangeEvent<HTMLInputElement>, error: string | null) => void;
  onChangeInputTwo: (event: React.ChangeEvent<HTMLInputElement>, error: string | null) => void;
  scale?: number;
  tooltipHelpTextKey: string;
}) {
  const [errorOne, setErrorOne] = useState<string | null>(null); // Estado para o erro do primeiro input
  const [errorTwo, setErrorTwo] = useState<string | null>(null); // Estado para o erro do segundo input
  const [actualValueOne, setActualValueOne] = useState<string | undefined>(undefined); // Estado para o valor atual
  const [actualValueTwo, setActualValueTwo] = useState<string | undefined>(undefined); // Estado para o valor atual

  useEffect(() => {
    if ((rowValue.valueInputOne as RowInputProp).validator) {
      const isError = (rowValue.valueInputOne as RowInputProp).validator?.((rowValue.valueInputOne as RowInputProp).value || '');
      if (isError) setErrorOne(isError); // Atualiza o erro se houver
    }
    if ((rowValue.valueInputTwo as RowInputProp).validator) {
      const isError = (rowValue.valueInputTwo as RowInputProp).validator?.((rowValue.valueInputTwo as RowInputProp).value || '');
      if (isError) setErrorTwo(isError); // Atualiza o erro se houver
    }
  }, []);

  // Função para validar e atualizar o estado de erro para o primeiro input
  const handleInputOneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace('.', ',');
    let isError = false;
    let validationError: string | null = null;
    const isSalarioRow = (rowValue.valueInputOne as RowInputProp)?.key.includes('salarios');
    const normalizedValue = Formarters.parseNumberWithComma(value);

    if ((rowValue.valueInputOne as RowInputProp).validator) {
      validationError = (rowValue.valueInputOne as RowInputProp).validator?.(value) ?? null;
      setErrorOne(validationError); // Atualiza o erro se houver
    }

    if (value !== '') {
      if (!normalizedValue && normalizedValue !== 0) {
        isError = true;
      }
      if (!isError && !isSalarioRow) {
        value = parseInt(value).toString();
      }
    }

    if (normalizedValue >= 0 || value === '') {
      if (!isError || isSalarioRow) {
        if (value !== 'NaN') {
          setActualValueOne(value);
          onChangeInputOne(event, validationError);
        }
      }
    }
  };

  // Função para validar e atualizar o estado de erro para o segundo input
  const handleInputTwoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace('.', ',');
    let isError = false;
    let validationError: string | null = null;
    const isSalarioRow = (rowValue.valueInputOne as RowInputProp)?.key.includes('salarios');
    const normalizedValue = Formarters.parseNumberWithComma(value);

    if ((rowValue.valueInputTwo as RowInputProp).validator) {
      validationError = (rowValue.valueInputTwo as RowInputProp).validator?.(value) ?? null;
      setErrorTwo(validationError); // Atualiza o erro se houver
    }

    if (value !== '') {
      if (!normalizedValue && normalizedValue !== 0) {
        isError = true;
      }
      if (!isError && !isSalarioRow) {
        value = parseInt(value).toString();
      }
    }

    if (normalizedValue >= 0 || value === '') {
      if (!isError || isSalarioRow) {
        if (value !== 'NaN') {
          setActualValueTwo(value);
          onChangeInputTwo(event, validationError);
        }
      }
    }
  };

  function notHasEmployees() {
    return (
      <Typography variant='h6' align='right' sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}>
        Não Possui
      </Typography>
    );
  }

  function buildTooltipText() {
    let text = '';
    if (hasOperators && !hasSales && Object.hasOwn(operatorHelpText, tooltipHelpTextKey)) {
      text += operatorHelpText[tooltipHelpTextKey];
    }
    if (hasSales && !hasOperators && Object.hasOwn(salerHelpText, tooltipHelpTextKey)) {
      text += salerHelpText[tooltipHelpTextKey];
    }
    if (
      hasSales &&
      hasOperators &&
      Object.hasOwn(operatorHelpText, tooltipHelpTextKey) &&
      Object.hasOwn(salerHelpText, tooltipHelpTextKey)
    ) {
      text += `${operatorHelpText[tooltipHelpTextKey]}\n\n${salerHelpText[tooltipHelpTextKey]}`;
    }
    return text;
  }

  return (
    <>
      <Grid item xs={12} sm={4} sx={{ placeSelf: 'center' }}>
        <Box display='flex' gap={scaleValue(scale, '4px')} alignItems='center' justifyContent='start'>
          <Typography sx={{ ...textStyle(scale) }}>{title}</Typography>
          <HelpTooltip tooltipText={buildTooltipText()} size={scaleValue(scale, 18) as number} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasOperators ? (
          <Input1
            defaultValue={(rowValue.valueInputOne as RowInputProp).value}
            value={actualValueOne}
            sx={{ marginTop: scaleValue(scale, '4px'), width: '100%' }}
            onChange={handleInputOneChange}
            error={!!errorOne} // Define o estado de erro
            helperText={errorOne || (rowValue.valueInputOne as RowInputProp).helperText} // Mostra o erro ou o helperText
            disabled={(rowValue.valueInputOne as RowInputProp).disabled}
            isControlledComponent
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasSales ? (
          <Input1
            defaultValue={(rowValue.valueInputTwo as RowInputProp).value}
            value={actualValueTwo}
            sx={{ marginTop: scaleValue(scale, '4px'), width: '100%' }}
            onChange={handleInputTwoChange}
            error={!!errorTwo} // Define o estado de erro
            helperText={errorTwo || (rowValue.valueInputTwo as RowInputProp).helperText} // Mostra o erro ou o helperText
            disabled={(rowValue.valueInputTwo as RowInputProp).disabled}
            isControlledComponent
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
    </>
  );
}

const maskMoneyUnlimited = (value: any) => {
  // Remove todos os caracteres que não são dígitos
  value = value.replace(/\D/g, '');

  // Se o valor for menor que 3 dígitos, ajuste para ter pelo menos 3
  // while (value.length < 3) {
  //   value = `0${value}`;
  // }

  // Adiciona a vírgula para os decimais
  value = value.replace(/(\d{2})$/, ',$1');

  // Adiciona o ponto para cada grupo de milhares
  value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  // Adiciona o símbolo de moeda
  return value;
};

function RowContainerSalario({
  title,
  hasOperators = true,
  hasSales = true,
  rowValue,
  onChangeInputOne,
  onChangeInputTwo,
  tooltipHelpTextKey = '',
  scale = 1,
}: {
  title: string;
  hasOperators?: boolean;
  hasSales?: boolean;
  rowValue: Row;
  onChangeInputOne: (event: React.ChangeEvent<HTMLInputElement>, error: string | null) => void;
  onChangeInputTwo: (event: React.ChangeEvent<HTMLInputElement>, error: string | null) => void;
  scale?: number;
  tooltipHelpTextKey: string;
}) {
  const [errorOne, setErrorOne] = useState<string | null>(null); // Estado para o erro do primeiro input
  const [errorTwo, setErrorTwo] = useState<string | null>(null); // Estado para o erro do segundo input
  const [actualValueOne, setActualValueOne] = useState<string | undefined>(undefined); // Estado para o valor atual
  const [actualValueTwo, setActualValueTwo] = useState<string | undefined>(undefined); // Estado para o valor atual

  useEffect(() => {
    if ((rowValue.valueInputOne as RowInputProp).validator) {
      const isError = (rowValue.valueInputOne as RowInputProp).validator?.((rowValue.valueInputOne as RowInputProp).value || '');
      if (isError) setErrorOne(isError); // Atualiza o erro se houver
    }
    if ((rowValue.valueInputTwo as RowInputProp).validator) {
      const isError = (rowValue.valueInputTwo as RowInputProp).validator?.((rowValue.valueInputTwo as RowInputProp).value || '');
      if (isError) setErrorTwo(isError); // Atualiza o erro se houver
    }
  }, []);

  // Função para validar e atualizar o estado de erro para o primeiro input
  const handleInputOneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace('.', ',');
    let isError = false;
    let validationError: string | null = null;
    const isSalarioRow = (rowValue.valueInputOne as RowInputProp)?.key.includes('salarios');
    const normalizedValue = Formarters.parseNumberWithComma(value);

    if ((rowValue.valueInputOne as RowInputProp).validator) {
      validationError = (rowValue.valueInputOne as RowInputProp).validator?.(value) ?? null;
      setErrorOne(validationError); // Atualiza o erro se houver
    }

    if (value !== '') {
      if (!normalizedValue && normalizedValue !== 0) {
        isError = true;
      }
      if (!isError && !isSalarioRow) {
        value = parseInt(value).toString();
      }
    }

    if (normalizedValue >= 0 || value === '') {
      if (!isError || isSalarioRow) {
        if (value !== 'NaN') {
          setActualValueOne(maskMoneyUnlimited(value));
          onChangeInputOne(event, validationError);
        }
      }
    }
  };

  // Função para validar e atualizar o estado de erro para o segundo input
  const handleInputTwoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace('.', ',');
    let isError = false;
    let validationError: string | null = null;
    const isSalarioRow = (rowValue.valueInputOne as RowInputProp)?.key.includes('salarios');
    const normalizedValue = Formarters.parseNumberWithComma(value);

    if ((rowValue.valueInputTwo as RowInputProp).validator) {
      validationError = (rowValue.valueInputTwo as RowInputProp).validator?.(value) ?? null;
      setErrorTwo(validationError); // Atualiza o erro se houver
    }

    if (value !== '') {
      if (!normalizedValue && normalizedValue !== 0) {
        isError = true;
      }
      if (!isError && !isSalarioRow) {
        value = parseInt(value).toString();
      }
    }

    if (normalizedValue >= 0 || value === '') {
      if (!isError || isSalarioRow) {
        if (value !== 'NaN') {
          setActualValueTwo(maskMoneyUnlimited(value));
          onChangeInputTwo(event, validationError);
        }
      }
    }
  };

  function notHasEmployees() {
    return (
      <Typography variant='h6' align='right' sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}>
        Não Possui
      </Typography>
    );
  }

  function buildTooltipText() {
    let text = '';
    if (hasOperators && !hasSales && Object.hasOwn(operatorHelpText, tooltipHelpTextKey)) {
      text += operatorHelpText[tooltipHelpTextKey];
    }
    if (hasSales && !hasOperators && Object.hasOwn(salerHelpText, tooltipHelpTextKey)) {
      text += salerHelpText[tooltipHelpTextKey];
    }
    if (
      hasSales &&
      hasOperators &&
      Object.hasOwn(operatorHelpText, tooltipHelpTextKey) &&
      Object.hasOwn(salerHelpText, tooltipHelpTextKey)
    ) {
      text += `${operatorHelpText[tooltipHelpTextKey]}\n\n${salerHelpText[tooltipHelpTextKey]}`;
    }
    return text;
  }

  return (
    <>
      <Grid item xs={12} sm={4} sx={{ placeSelf: 'center' }}>
        <Box display='flex' gap={scaleValue(scale, '4px')} alignItems='center' justifyContent='start'>
          <Typography sx={{ ...textStyle(scale) }}>{title}</Typography>
          <HelpTooltip tooltipText={buildTooltipText()} size={scaleValue(scale, 18) as number} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasOperators ? (
          <Input1
            defaultValue={(rowValue.valueInputOne as RowInputProp).value}
            value={actualValueOne}
            sx={{ marginTop: scaleValue(scale, '4px'), width: '100%' }}
            onChange={handleInputOneChange}
            error={!!errorOne} // Define o estado de erro
            helperText={errorOne || (rowValue.valueInputOne as RowInputProp).helperText} // Mostra o erro ou o helperText
            disabled={(rowValue.valueInputOne as RowInputProp).disabled}
            isControlledComponent
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasSales ? (
          <Input1
            defaultValue={(rowValue.valueInputTwo as RowInputProp).value}
            value={actualValueTwo}
            sx={{ marginTop: scaleValue(scale, '4px'), width: '100%' }}
            onChange={handleInputTwoChange}
            error={!!errorTwo} // Define o estado de erro
            helperText={errorTwo || (rowValue.valueInputTwo as RowInputProp).helperText} // Mostra o erro ou o helperText
            disabled={(rowValue.valueInputTwo as RowInputProp).disabled}
            isControlledComponent
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
    </>
  );
}

function DropdownSelectBoxState({
  scale,
  options,
  dropdownValueParam,
  onSelect,
  disabled,
}: {
  scale: number;
  options: DropdownSelectOption[];
  dropdownValueParam?: DropdownSelectOption;
  onSelect: (option: DropdownSelectOption) => void;
  disabled?: boolean;
}) {
  const [dropdownValue, setDropdownValue] = useState<DropdownSelectOption | undefined>(dropdownValueParam);

  const handleChangeSelect = (option: DropdownSelectOption) => {
    setDropdownValue(option);
    onSelect(option);
  };

  return (
    <DropdownSelect
      boxStyle={{
        marginTop: scaleValue(scale, '4px'),
        marginLeft: 'auto',
        width: '100%',
        boxSizing: 'border-box',
      }}
      label=''
      options={options}
      dropdownValue={dropdownValue}
      onSelect={handleChangeSelect}
      disabled={disabled}
    />
  );
}

function RowContainerSelect({
  title,
  hasOperators = true,
  hasSales = true,
  rowValue,
  onChangeInputOne,
  onChangeInputTwo,
  tooltipHelpTextKey = '',
  scale = 1,
  isTimeOver,
}: {
  title: string;
  hasOperators?: boolean;
  hasSales?: boolean;
  rowValue: Row;
  onChangeInputOne: (option: DropdownSelectOption) => void;
  onChangeInputTwo: (option: DropdownSelectOption) => void;
  tooltipHelpTextKey: string;
  scale?: number;
  isTimeOver?: boolean;
}) {
  function notHasEmployees() {
    return (
      <Typography variant='h6' align='right' sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}>
        Não Possui
      </Typography>
    );
  }

  function buildTooltipText() {
    let text = '';
    if (hasOperators && !hasSales && Object.hasOwn(operatorHelpText, tooltipHelpTextKey)) {
      text += operatorHelpText[tooltipHelpTextKey];
    }
    if (hasSales && !hasOperators && Object.hasOwn(salerHelpText, tooltipHelpTextKey)) {
      text += salerHelpText[tooltipHelpTextKey];
    }
    if (
      hasSales &&
      hasOperators &&
      Object.hasOwn(operatorHelpText, tooltipHelpTextKey) &&
      Object.hasOwn(salerHelpText, tooltipHelpTextKey)
    ) {
      text += `${operatorHelpText[tooltipHelpTextKey]}\n\n${salerHelpText[tooltipHelpTextKey]}`;
    }
    return text;
  }

  return (
    <>
      <Grid item xs={12} sm={4} sx={{ placeSelf: 'center' }}>
        <Box display='flex' gap={scaleValue(scale, '4px')} alignItems='center' justifyContent='start'>
          <Typography sx={{ ...textStyle(scale) }}>{title}</Typography>
          <HelpTooltip tooltipText={buildTooltipText()} size={scaleValue(scale, 18) as number} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasOperators ? (
          <DropdownSelectBoxState
            scale={scale}
            options={rowValue.valueInputOne as DropdownSelectOption[]}
            dropdownValueParam={(rowValue.valueInputOne as DropdownSelectOption[])[0].defaultValue}
            onSelect={onChangeInputOne}
            disabled={isTimeOver}
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasSales ? (
          <DropdownSelectBoxState
            scale={scale}
            options={rowValue.valueInputTwo as DropdownSelectOption[]}
            dropdownValueParam={(rowValue.valueInputTwo as DropdownSelectOption[])[0].defaultValue}
            onSelect={onChangeInputTwo}
            disabled={isTimeOver}
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
    </>
  );
}

export default function RhContainer({
  rowOne,
  rowTwo,
  rowThree,
  rowFour,
  rowFive,
  rowSix,
  rowSeven,
  onChange,
  scale = 1,
  isTimeOver,
}: RhContainerProps) {
  const values: any = {};

  const handleChangeInput = (key: string, value: string, error: string | null) => {
    values[key] = value;
    onChange(values, error);
  };

  const handleChangeSelect = (option: DropdownSelectOption) => {
    values[option.key ?? option.label] = option.value;
    onChange(values, null);
  };

  return (
    <Box1
      sx={{
        height: 'auto',
        width: '95%',
        boxSizing: 'border-box',
        padding: scaleValue(scale, '23px 40px'),
      }}
    >
      <Grid container spacing={scaleValue(scale, 2)}>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={4}>
          <Typography variant='h6' align='right' sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}>
            Operacionais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='h6' align='right' sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}>
            Vendedores
          </Typography>
        </Grid>

        <RowContainer
          title='Admitidos'
          rowValue={rowOne}
          onChangeInputOne={(event, error) => handleChangeInput((rowOne.valueInputOne as RowInputProp).key, event.target.value, error)}
          onChangeInputTwo={(event, error) => handleChangeInput((rowOne.valueInputTwo as RowInputProp).key, event.target.value, error)}
          tooltipHelpTextKey='admitidos'
          scale={scale}
        />
        <RowContainer
          title='Demitidos'
          rowValue={rowTwo}
          onChangeInputOne={(event, error) => handleChangeInput((rowTwo.valueInputOne as RowInputProp).key, event.target.value, error)}
          onChangeInputTwo={(event, error) => handleChangeInput((rowTwo.valueInputTwo as RowInputProp).key, event.target.value, error)}
          tooltipHelpTextKey='demitidos'
          scale={scale}
        />
        <RowContainerSalario
          title='Salários($)'
          rowValue={rowThree}
          onChangeInputOne={(event, error) => handleChangeInput((rowThree.valueInputOne as RowInputProp).key, event.target.value, error)}
          onChangeInputTwo={(event, error) => handleChangeInput((rowThree.valueInputTwo as RowInputProp).key, event.target.value, error)}
          tooltipHelpTextKey='salario'
          scale={scale}
        />
        <RowContainerSelect
          title='Comissão'
          hasOperators={false}
          rowValue={rowFour}
          onChangeInputOne={handleChangeSelect}
          onChangeInputTwo={handleChangeSelect}
          tooltipHelpTextKey='comissao'
          scale={scale}
          isTimeOver={isTimeOver}
        />
        <RowContainer
          title='Hora Extra'
          rowValue={rowFive}
          onChangeInputOne={(event, error) => handleChangeInput((rowFive.valueInputOne as RowInputProp).key, event.target.value, error)}
          onChangeInputTwo={(event, error) => handleChangeInput((rowFive.valueInputTwo as RowInputProp).key, event.target.value, error)}
          tooltipHelpTextKey='horaExtra'
          scale={scale}
        />
        <RowContainerSelect
          title='Treinamento'
          rowValue={rowSix}
          onChangeInputOne={handleChangeSelect}
          onChangeInputTwo={handleChangeSelect}
          tooltipHelpTextKey='treinamento'
          scale={scale}
          isTimeOver={isTimeOver}
        />
        <RowContainerSelect
          title='Participação'
          hasSales={false}
          rowValue={rowSeven}
          onChangeInputOne={handleChangeSelect}
          onChangeInputTwo={handleChangeSelect}
          tooltipHelpTextKey='participacao'
          scale={scale}
          isTimeOver={isTimeOver}
        />
      </Grid>
    </Box1>
  );
}
