import React from 'react';
import { IconCard } from '../../Atomos/IconCard';
import { ReactComponent as RhIcon } from './RhIcon.svg';
import { ReactComponent as ColumnAverage } from './ColumnAverage.svg';
import { ReactComponent as InvoiceTip } from './InvoiceTip.svg';
import { ReactComponent as Sign } from './Sign.svg';
import { ReactComponent as StackedBarUp } from './StackedBarUp.svg';

const ICONS = ['RhIcon', 'ColumnAverage', 'InvoiceTip', 'Sign', 'StackedBarUp'] as const;

export type IconName = (typeof ICONS)[number];

export type IconData = {
  name: IconName;
  label: string;
};

export type IconCardListProps = {
  icons: IconData[];
  onChange: (icon: IconData) => void;
  initialSelected: IconName;
};

const iconMap: Record<IconName, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  RhIcon,
  ColumnAverage,
  InvoiceTip,
  Sign,
  StackedBarUp,
};

export default function IconCardList({ icons, onChange, initialSelected }: IconCardListProps): JSX.Element {
  const [isSelected, setIsSelected] = React.useState(initialSelected);

  const handleIconClick = (icon: IconData) => {
    setIsSelected(icon.name);
    onChange(icon);
  };

  return (
    <div style={{ display: 'flex', gap: '28px' }}>
      {icons.map((icon, index) => {
        const IconComponent = iconMap[icon.name];
        return (
          <IconCard
            key={index}
            label={icon.label}
            isSelected={icon.name === isSelected}
            icon={<IconComponent />}
            onClick={() => handleIconClick(icon)}
          />
        );
      })}
    </div>
  );
}
