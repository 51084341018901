import { Typography, CircularProgress, useMediaQuery } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import React, { useEffect } from 'react';
import { Box1, TimeTracking, Button3 } from '../../../ui/index';
import { EtapaDTO } from '../../../dto/etapaDTO';
import { calculateMinutesUntilDate } from '../../../lib/utils';

export type BottomContainerProps = {
  sendButtonActive: boolean;
  boxStyle?: React.CSSProperties;
  etapa: EtapaDTO;
  sendFunction: () => Promise<void>;
  nextStepFunction: () => Promise<void>;
};

const deactiveButton: React.CSSProperties = {
  color: 'white',
  fontSize: '13px',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0.15',
  wordWrap: 'break-word',
  background: '#666666',
};

const nextStepButton: React.CSSProperties = {
  color: 'white',
  fontSize: '13px',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0.15',
  wordWrap: 'break-word',
  background: '#007B36',
};

export default function BottomContainer({
  etapa,
  sendButtonActive,
  boxStyle,
  sendFunction,
  nextStepFunction,
}: BottomContainerProps): JSX.Element {
  const [isSending, setIsSending] = React.useState(false);
  const [isTimeOver, setIsTimeOver] = React.useState(false);
  const ultraMinDesktopSize = useMediaQuery('(min-width: 1020px)');
  const minDesktopSize = useMediaQuery('(min-width: 1200px)');
  const mediumDesktopSize = useMediaQuery('(min-width: 1440px)');
  const largeDesktopSize = useMediaQuery('(min-width: 1920px)');

  useEffect(() => {
    setIsTimeOver(calculateMinutesUntilDate(etapa.cronometro) <= 0);
    localStorage.setItem('isTimeOver', (calculateMinutesUntilDate(etapa.cronometro) <= 0).toString());
    window.dispatchEvent(new Event('isTimeOverChange'));
  }, [etapa]);

  useEffect(() => {
    if (calculateMinutesUntilDate(etapa.cronometro) <= 0) {
      setIsTimeOver(true);
      localStorage.setItem('isTimeOver', true.toString());
    }
  }, []);

  const handleNextStepFunction = async () => {
    localStorage.setItem('ignoreBeforeUnload', 'true');
    await nextStepFunction();
  };

  const handleSendClick = async () => {
    setIsSending(true); // Inicia o estado de loading
    try {
      if (isTimeOver) await handleNextStepFunction();
      else await sendFunction(); // Aguarda a função sendFunction ser resolvida
    } finally {
      setIsSending(false); // Termina o estado de loading
    }
  };

  const onTimeEnd = () => {
    setIsTimeOver(true);
    localStorage.setItem('isTimeOver', true.toString());
    window.dispatchEvent(new Event('isTimeOverChange'));
  };

  const endText = () => {
    if (largeDesktopSize) return 'Essa Etapa Acaba Em:';
    if (mediumDesktopSize) return 'Etapa Acaba Em:';
    if (minDesktopSize) return ' Etapa Acaba Em:';
    if (ultraMinDesktopSize) return 'Acaba Em:';
    return '';
  };

  function renderButton() {
    if (isTimeOver) {
      return (
        <Button3
          onClick={handleSendClick}
          disabled={isSending} // Desativa o botão se estiver enviando ou se o botão não estiver ativo
          child={
            isSending ? (
              <CircularProgress size={24} color='inherit' /> // Indicador de loading dentro do botão
            ) : (
              'Pŕoxima Etapa'
            )
          }
          sx={nextStepButton}
        />
      );
    }
    return (
      <Button3
        onClick={handleSendClick}
        disabled={isTimeOver || isSending || !sendButtonActive} // Desativa o botão se estiver enviando ou se o botão não estiver ativo
        child={
          isSending ? (
            <CircularProgress size={24} color='inherit' /> // Indicador de loading dentro do botão
          ) : (
            'Enviar Decisão'
          )
        }
        sx={
          sendButtonActive || isSending
            ? {
                width: '181px',
              }
            : deactiveButton
        }
      />
    );
  }

  return (
    <Box1
      sx={{
        width: '1318px',
        height: '71px',
        padding: '15px 24px 15px 28px',
        alignItems: 'center',
        justifyContent: 'space-around',
        background: 'white',
        ...boxStyle,
      }}
    >
      <Box1 sx={{ height: '28px', width: '229px', padding: '7px 20px', alignItems: 'center' }}>
        <CalendarTodayOutlinedIcon />
        <Typography>{etapa.numero_etapa}º Trimestre</Typography>
      </Box1>
      <Box1 sx={{ height: '28px', width: '576px', padding: '7px 20px', alignItems: 'center' }}>
        <CalendarTodayOutlinedIcon />
        <Typography>{endText()}</Typography>
        <TimeTracking beginTimeMinutes={calculateMinutesUntilDate(etapa.cronometro)} onEnd={() => onTimeEnd()} />
      </Box1>
      {renderButton()}
    </Box1>
  );
}
