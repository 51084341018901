import { Box, Grid, Typography, Card } from '@mui/material';
import { Formarters } from '../../../../lib/formaters';
import { IndicatorsDTO } from '../../../../dto';

export type BuildingCostProps = {
  dataIndicator: IndicatorsDTO | undefined;
};

export default function BuildingCostSection({ dataIndicator }: BuildingCostProps): JSX.Element {
  return (
    <Box width='85%' mt={{ xs: 6, sm: 6, md: 4, lg: 4 }}>
      <Typography color='#00AB4A' fontSize='clamp(1.5rem, 2.5vw, 1.8rem)' fontFamily='Poppins' fontWeight={700}>
        Custos por M2
      </Typography>
      <Grid container spacing={2} pt={3}>
        {dataIndicator &&
          Object.entries(dataIndicator.custo_por_m2).map(([key, value]) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={key}>
              <Card
                sx={{
                  width: '100%',
                  backgroundColor: '#FAFAFA',
                  borderRadius: '8px',
                  variant: 'outlined',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
                variant='outlined'
              >
                <Box sx={{ padding: '35px 32px', display: 'flex', justifyContent: 'center' }}>
                  <Typography fontSize='clamp(1.25rem, 2.5vw, 1.45rem)' fontFamily='Poppins' fontWeight={400} color='#666666'>
                    O custo para <span style={{ fontWeight: 900 }}>{Formarters.translateKeyIndicators(key)}</span> por M2 será de:{' '}
                    <span style={{ fontWeight: 900 }}> ${Formarters.formatHelperText(value)}</span>
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
