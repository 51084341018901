import { CircularProgress, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useRelatorioResults, useRelatorioInitialData } from './api/relatorio';
import { isEdgeBrowser } from './lib/utils';

export function ReportInitialData(): JSX.Element {
  const jogoId = localStorage.getItem('jogo_id') ?? '';
  // const showHtml = isEdgeBrowser();
  const showHtml = false;

  const { data, isLoading, error } = useRelatorioInitialData(jogoId);

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress color='success' size={60} />
      </Box>
    );
  if (error) return <div>Não possível acessar esse relatório. Tente novamente!</div>;

  function renderHtmlOrPdf() {
    if (showHtml) {
      return <iframe srcDoc={data} style={{ width: '100vw', height: '100vh', border: 'none' }} title='Report' />;
    } else {
      return <iframe src={data} style={{ width: '100%', height: '100vh', border: 'none' }} title='Report' />;
    }
  }

  return data ? renderHtmlOrPdf() : <div>Sem Conteudo.</div>;
}

export default function Report(): JSX.Element {
  const jogoId = localStorage.getItem('jogo_id') ?? '';
  // const showHtml = isEdgeBrowser();
  const showHtml = false;
  const { type, stage } = useParams();
  const reportType = type === 'company' ? 'empresa' : 'mercado';

  const { data, isLoading, error } = useRelatorioResults(jogoId, reportType, stage || '0', showHtml);

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress color='success' size={60} />
      </Box>
    );
  if (error) return <div>Não possível acessar esse relatório. Tente novamente!</div>;

  function renderHtmlOrPdf() {
    if (showHtml) {
      return <iframe srcDoc={data} style={{ width: '100vw', height: '100vh', border: 'none' }} title='Report' />;
    } else {
      return <iframe src={data} style={{ width: '100%', height: '100vh', border: 'none' }} title='Report' />;
    }
  }

  return data ? renderHtmlOrPdf() : <div>Sem Conteudo.</div>;
}
