import { Typography } from '@mui/material';
import React from 'react';
import Box1 from '../Box';
import { scaleValue } from '../../../utils'; // Função scaleValue

const titleStyle: React.CSSProperties = {
  color: 'black',
  fontSize: '15px', // Escalável
  fontFamily: 'Poppins',
  fontWeight: '700',
  letterSpacing: 0.15,
  wordWrap: 'break-word',
};

const subtitleStyle: React.CSSProperties = {
  color: '#00AB4A',
  fontSize: '23px', // Escalável
  fontFamily: 'Poppins',
  fontWeight: '700',
  letterSpacing: 0.15,
  wordWrap: 'break-word',
};

export default function MoneyBox({
  color,
  subtitle,
  title,
  scale,
  boxStyle,
}: {
  color?: string;
  subtitle: string;
  title: string;
  scale?: number | undefined;
  boxStyle?: React.CSSProperties;
}): JSX.Element {
  scale = scale ?? 1;

  return (
    <Box1
      display='flex'
      flexDirection='column'
      sx={{
        padding: scaleValue(scale, '18px 32px', 0.2), // Escalando o padding
        width: scaleValue(scale, '264px', 0.2), // Escalando a largura
        height: scaleValue(scale, '100px', 0.2), // Escalando a altura
        ...boxStyle,
      }}
    >
      <Typography
        sx={{
          ...titleStyle,
          fontSize: scaleValue(scale, titleStyle.fontSize as string, 0.3), // Escalando a fonte
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          ...subtitleStyle,
          fontSize: scaleValue(scale, subtitleStyle.fontSize as string, 0.3), // Escalando a fonte
          color: color ?? '#00AB4A',
        }}
      >
        {subtitle}
      </Typography>
    </Box1>
  );
}
