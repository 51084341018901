import { Box, Grid, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

import { DropdownSelect } from '../../../../ui/index';

export type DropdownSelectOption = {
  label: string;
  value: string;
  key?: string;
  defaultValue?: DropdownSelectOption;
};

export type NewsHeaderProps = {
  gameName?: string;
  gameEtapa?: any;
  hasSelect: boolean;
  onUpdateState?: (value: number) => void;
};

export default function NewsHeader({ gameName, gameEtapa, hasSelect, onUpdateState }: NewsHeaderProps): JSX.Element {
  const etapaAtual = localStorage.getItem('numero_etapa') ?? '0';

  const [options, setOptions] = useState<DropdownSelectOption[]>([]);
  const [dropdownValues, setDropdownValues] = useState<DropdownSelectOption>({ label: '', value: '' });

  useEffect(() => {
    const parsedEtapaAtual = parseInt(etapaAtual);

    const newOptions: DropdownSelectOption[] = [];

    for (let i = 2; i <= parsedEtapaAtual; i++) {
      newOptions.push({ label: `Etapa ${i}`, value: i.toString() });
    }

    setOptions(newOptions);

    const etapaValue = gameEtapa?.toString();
    const foundOption = newOptions.find((option) => option.value === etapaValue);

    if (foundOption) {
      setDropdownValues(foundOption);
    }
  }, [etapaAtual]);

  const handleChangeSelect = (option: DropdownSelectOption) => {
    setDropdownValues(option);
    onUpdateState?.(parseInt(option.value));
  };

  return (
    <Box
      mt={1}
      sx={{
        backgroundColor: '#00AB4A',
        width: '85%',
        minHeight: '95px',
        border: '2px solid #007B36',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid container alignItems='center' justifyContent='space-between' px={2} pt={{ xs: 2, sm: 2, md: 0, lg: 0 }}>
        <Grid item xs={6} sm={6} md={4} lg={4} container justifyContent='flex-start'>
          <Typography
            fontSize='clamp(1rem, 2.5vw, 1.125rem)'
            fontFamily='Poppins'
            fontWeight={600}
            color='#fff'
            lineHeight='25px'
            textAlign='left'
          >
            {gameName}
          </Typography>
        </Grid>
        <Grid
          sx={{ order: { xs: 1, sm: 1, md: 0, lg: 0, xl: 0 } }}
          py={{ xs: 2, sm: 2, md: 0, lg: 0, xl: 0 }}
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          container
          justifyContent='center'
        >
          <Box sx={{ textAlign: 'center' }}>
            <img src='/xskill-news.png' width='300px' height='46px' alt='news-img' />
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4} container justifyContent='flex-end'>
          {hasSelect ? (
            <DropdownSelect
              boxStyle={{ width: '65%' }}
              label=''
              options={options}
              dropdownValue={dropdownValues}
              onSelect={(option) => handleChangeSelect(option)}
            />
          ) : (
            <Typography
              fontSize='clamp(1rem, 2.5vw, 1.125rem)'
              fontFamily='Poppins'
              fontWeight={600}
              color='#fff'
              lineHeight='25px'
              textAlign='right'
            >
              {gameEtapa && `Etapa ${gameEtapa}`}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
