import { Box, Grid, Typography, Card } from '@mui/material';
import { Formarters } from '../../../../lib/formaters';
import { IndicatorsDTO } from '../../../../dto';

export type FinesSectionProps = {
  dataIndicator: IndicatorsDTO | undefined;
};

export default function FinesSection({ dataIndicator }: FinesSectionProps): JSX.Element {
  return (
    <Box width='85%' mt={{ xs: 6, sm: 6, md: 4, lg: 4 }}>
      <Typography color='#00AB4A' fontSize='clamp(1.5rem, 2.5vw, 1.8rem)' fontFamily='Poppins' fontWeight={700}>
        Juros/Multa
      </Typography>
      <Grid container spacing={2} pt={3}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card
            sx={{
              width: '100%',
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
              variant: 'outlined',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
            variant='outlined'
          >
            <Box sx={{ padding: '35px 32px' }}>
              <Typography fontSize='clamp(1.5rem, 2.5vw, 2rem)' fontFamily='Poppins' fontWeight={700}>
                Juros
              </Typography>
              {dataIndicator &&
                Object.entries(dataIndicator)
                  .filter(([key]) => key.startsWith('juro'))
                  .map(([key, value]) => (
                    <Box display='flex' justifyContent='space-between' key={key} py={0.65}>
                      <Typography fontSize='15px' fontFamily='Poppins' fontWeight={400} color='#666666'>
                        {Formarters.translateKeyIndicators(key)}
                      </Typography>
                      {typeof value === 'number' && (
                        <Typography fontSize='15px' fontFamily='Poppins' fontWeight={700} color='#666666'>
                          {Formarters.formatValuesIndicators(key, value)}
                        </Typography>
                      )}
                    </Box>
                  ))}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card
            sx={{
              width: '100%',
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
              variant: 'outlined',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
            variant='outlined'
          >
            <Box sx={{ padding: '35px 32px' }}>
              <Typography fontSize='clamp(1.5rem, 2.5vw, 2rem)' fontFamily='Poppins' fontWeight={700}>
                Multas
              </Typography>
              {dataIndicator &&
                Object.entries(dataIndicator)
                  .filter(([key]) => key.startsWith('multa'))
                  .map(([key, value]) => (
                    <Box display='flex' justifyContent='space-between' key={key} py={0.65}>
                      <Typography fontSize='15px' fontFamily='Poppins' fontWeight={400} color='#666666'>
                        {Formarters.translateKeyIndicators(key)}
                      </Typography>
                      {typeof value === 'number' && (
                        <Typography fontSize='15px' fontFamily='Poppins' fontWeight={700} color='#666666'>
                          {Formarters.formatValuesIndicators(key, value)}
                        </Typography>
                      )}
                    </Box>
                  ))}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
