import { Box, Typography, SxProps } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import React from 'react';
import UserProfileBox from '../../Moleculas/UserProfileBox';
import NavigationButtons from '../../Moleculas/NavigationButtons';
import RankingBox from '../../Moleculas/RankingBox';
import Button1 from '../../Atomos/Button1';
import { scaleValue } from '../../../utils';

type LeftContainerProps = {
  boxStyle?: React.CSSProperties;
};

const scale = 1;

const scaledBoxStyle: React.CSSProperties = {
  width: scaleValue(scale, 302 - 24 * 2),
  height: scaleValue(scale, 1080 - 38 * 2),
  background: 'white',
  border: '1px #E0E0E0 solid',
  padding: `${scaleValue(scale, 38)}px ${scaleValue(scale, 24)}px`,
};

export default function LeftContainer({ boxStyle }: LeftContainerProps): JSX.Element {
  return (
    <Box display='flex' flexDirection='column' sx={{ ...scaledBoxStyle, ...boxStyle }}>
      <UserProfileBox
        name='John Doe'
        company='Empresa X'
        scenario='Cenário X'
        src='https://via.placeholder.com/35x35'
        scenarioCircleColor='green'
        boxStyle={{ marginBottom: scaleValue(scale, '56px'), width: 'auto' }}
      />
      <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
        <Box>
          <Typography
            sx={{
              color: 'black',
              fontSize: 16,
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Navegue
          </Typography>
          <NavigationButtons onChange={() => null} boxStyle={{ marginBottom: scaleValue(scale, '12px') }} />
          <RankingBox subtitle='Ranking de usuários' names={['Concorrente 1', 'Concorrente 2', 'Concorrente 3']} />
        </Box>
        <Button1 text='Voltar' icon={<LogoutOutlinedIcon sx={{ fontSize: scaleValue(scale, 20) }} />} isActive />
      </Box>
    </Box>
  );
}
