import { Typography, Box } from '@mui/material';
import React from 'react';
import Box1 from '../../Atomos/Box';
import { ReactComponent as TrophyIcon } from './TrophyIcon.svg';

function RankingTiles({ name, position }: { name: string; position: number }): JSX.Element {
  return (
    <div
      style={{
        background: 'linear-gradient(0deg, #F3F3F3 0%, #F3F3F3 100%), #FFF',
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 9,
        border: '1px #E0E0E0 solid',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        display: 'inline-flex',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          color: '#666666',
          fontSize: 12,
          fontFamily: 'Poppins',
          fontWeight: '400',
          wordWrap: 'break-word',
          display: 'flex',
          gap: 14,
          alignItems: 'flex-end',
          justifyContent: 'space-evenly',
          width: '100%',
        }}
      >
        <span style={{ fontWeight: 'bold', fontSize: 15 }}>{position}.</span>
        <span>{name}</span>
      </div>
    </div>
  );
}

export default function RankingBox({ subtitle, names }: { subtitle: string; names: string[] }): JSX.Element {
  return (
    <Box1
      display='flex'
      flexDirection='column'
      sx={{ padding: '6px 6px 10px 6px', alignItems: 'center', justifyContent: 'center', height: 'auto', width: 'auto' }}
    >
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Typography style={{ color: 'black', fontSize: 18, fontFamily: 'Poppins', fontWeight: '600', wordWrap: 'break-word' }}>
          Ranking
        </Typography>
        <Typography style={{ color: 'black', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word' }}>
          {subtitle}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap='8px'>
        {names.map((name, index) => (
          <RankingTiles key={index} name={name} position={index + 1} />
        ))}
      </Box>
      <TrophyIcon />
    </Box1>
  );
}
