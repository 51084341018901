import { useMutation, useQuery } from 'react-query';
import { FolhaDecisaoDTO } from '../dto';
import { GameDetailsDTO } from '../dto/gameDetailsDTO';
import { roundNumbersInObject } from '../lib/utils';

// id_etapa=75&id_empresas=96
// id_etapa=77&id_empresas=100

const fetchGameDetails = async (jogo_id: string): Promise<GameDetailsDTO> => {
  const token = localStorage.getItem('token'); // Pegando o token do localStorage

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/jogos/${jogo_id}/all`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch game details');
  }

  return response.json();
};

const fetchGameResults = async (jogo_id: string): Promise<FolhaDecisaoDTO> => {
  const token = localStorage.getItem('token'); // Pegando o token do localStorage
  const id_etapa = localStorage.getItem('id_etapa');
  const id_empresas = localStorage.getItem('id_empresas');

  // jogos/${jogo_id}/folha_decisao
  const response = await fetch(
    `
  ${process.env.REACT_APP_BACKEND_URL}/jogos/${jogo_id}/folha_decisao?id_etapa=${id_etapa}&id_empresas=${id_empresas}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch game results');
  }

  return roundNumbersInObject(await response.json());
};

const sendGameResults = async (jogo_id: string, body: any): Promise<FolhaDecisaoDTO | JSON> => {
  const token = localStorage.getItem('token'); // Pegando o token do localStorage
  const id_etapa = localStorage.getItem('id_etapa');
  const id_empresas = localStorage.getItem('id_empresas');

  // jogos/${jogo_id}/folha_decisao
  const response = await fetch(
    `
  ${process.env.REACT_APP_BACKEND_URL}/jogos/${jogo_id}/folha_decisao?id_etapa=${id_etapa}&id_empresas=${id_empresas}`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error(JSON.stringify({ ...(await response.json()), statusCode: response.status }));
  }

  return roundNumbersInObject(await response.json());
};

export function useGameDetails(jogo_id: string) {
  return useQuery({
    queryKey: ['gameDetails', jogo_id],
    queryFn: () => fetchGameDetails(jogo_id),
    staleTime: Infinity,
  });
}

export function useGameResults(jogo_id: string) {
  return useQuery({
    queryKey: ['gameResults', jogo_id],
    queryFn: () => fetchGameResults(jogo_id),
    staleTime: Infinity,
  });
}

export function useSendGameResults(jogo_id: string) {
  return useMutation((body: any) => sendGameResults(jogo_id, body), {
    onError: (error: any, variables, context) => {
      // Acessa os detalhes do erro
      if (error.response) {
        // O erro tem uma resposta HTTP
        return error.response.data; // Retorna os dados da resposta de erro
      }
      if (error.request) {
        // O erro aconteceu durante a requisição, mas não houve resposta
      } else {
        // Outro tipo de erro
        return error.message;
      }

      return null;
    },
  });
}
