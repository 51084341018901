/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { loginWithToken } from './api/login'; // Importe a função de login que você criou
import MainPage from './App'; // Componente que será redirecionado após o login
import ErrorPage from './ErrorPage'; // Página de erro
import Indicators from './Indicators';
import NewsDetail from './NewsDetail';
import Report, { ReportInitialData } from './Report';
import News from './News';

const queryClient = new QueryClient();

const Bootstrap: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    // Função de login automático
    const autoLogin = async () => {
      try {
        // Tente fazer login com credenciais salvas (ou pré-definidas)
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        const jogo_id = queryParams.get('jogo_id');
        const id_etapa = queryParams.get('id_etapa');
        const id_empresa = queryParams.get('id_empresa');

        localStorage.setItem('jogo_id', jogo_id ?? '');
        localStorage.setItem('id_etapa', id_etapa ?? '');
        localStorage.setItem('id_empresas', id_empresa ?? '');

        sessionStorage.clear();
        await loginWithToken(token ?? '');
        setIsAuthenticated(true); // Autenticado com sucesso
      } catch (err) {
        setError(true); // Se der erro, define como erro
      } finally {
        setLoading(false); // Finaliza o loading
      }
    };

    autoLogin();
  }, []);

  // Renderiza um "Loading" enquanto o login está sendo feito
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Preenche toda a altura da viewport
        }}
      >
        <CircularProgress color='success' size={80} />
      </Box>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          {/* Rotas públicas */}
          <Route path='/error' element={<ErrorPage />} />

          {/* Rotas privadas: Redireciona para o MainPage se autenticado, ou para a página de erro se falhar */}
          <Route path='/mainpage' element={isAuthenticated ? <MainPage queryClient={queryClient} /> : <Navigate to='/error' />} />
          <Route path='/report/:type/:stage' element={isAuthenticated ? <Report /> : <Navigate to='/error' />} />
          <Route path='/report/initialData' element={isAuthenticated ? <ReportInitialData /> : <Navigate to='/error' />} />
          <Route path='/indicators' element={isAuthenticated ? <Indicators /> : <Navigate to='/error' />} />
          <Route path='/news/:id' element={isAuthenticated ? <NewsDetail /> : <Navigate to='/error' />} />
          <Route path='/news' element={isAuthenticated ? <News /> : <Navigate to='/error' />} />

          {/* Redireciona o usuário para o dashboard após login bem-sucedido */}
          <Route path='*' element={<Navigate to='/mainpage' />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
};

export default Bootstrap;
