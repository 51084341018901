import { Box, Grid, Typography, Divider } from '@mui/material';

export type NewsCardProps = {
  news: any;
  onClick: () => void;
};

export default function NewsCard({ news, onClick }: NewsCardProps): JSX.Element {
  return (
    <Box>
      <Grid
        onClick={onClick}
        container
        sx={{
          cursor: 'pointer',
          borderRadius: '15px',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: '#f0f0f0',
            borderRadius: '15px',
          },
        }}
      >
        <Grid item xs={12} sm={12} md={5} lg={4} xl={3.5}>
          <img style={{ borderRadius: '15px', width: '100%' }} src={news.url_imagem_destaque} alt='Imagem noticia' />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8} xl={8.5} pt={{ xs: 1, sm: 1, md: 0, lg: 0, xl: 0.5 }}>
          <Box sx={{ marginLeft: { xs: '0px', sm: '0px', md: '25px', lg: '25px', xl: '25px' } }}>
            <Typography color='#00AB4A' fontSize='clamp(1.5rem, 2.5vw, 2.5rem)' fontFamily='Poppins' fontWeight={700} lineHeight='40px'>
              {news.materia_titulo}
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: news.materia_texto.length > 207 ? `${news.materia_texto.substring(0, 207)}...` : news.materia_texto,
              }}
            />
            <Typography fontSize='15px' fontFamily='Poppins' fontWeight={400} color='#666666'>
              Etapa {news.numero_etapa}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ margin: '35px 0px', color: '#E0E0E0' }} />
    </Box>
  );
}
