import { Box } from '@mui/material';
import React from 'react';
import { TabTable } from '../../../ui/index';
import { FolhaDecisaoDTO } from '../../../dto';
import { EtapaDTO } from '../../../dto/etapaDTO';
import { GameDetailsDTO } from '../../../dto/gameDetailsDTO';
import MyStockContent from './MyStockContent';
import MidContainerContent from './MidContainerContent';
import ReportContainer from '../ReportContainer';

function MidContainer({
  folhaDecisaoData,
  dataEtapa,
  gameDetailsDTO,
  scale = 1,
  boxStyle,
  onChange,
}: {
  folhaDecisaoData: FolhaDecisaoDTO;
  dataEtapa: EtapaDTO;
  gameDetailsDTO: GameDetailsDTO;
  scale?: number;
  boxStyle?: React.CSSProperties;
  onChange: (data: any) => void;
}) {
  return (
    <Box sx={{ height: '80%', alignContent: 'center', ...boxStyle }}>
      <TabTable
        tabBoxStyle={{ position: 'relative', height: '100%' }}
        tabs={[
          {
            tabTitle: 'Tomada de Decisão',
            tabChild: (
              <MidContainerContent
                scale={scale}
                onChange={onChange}
                folhaDecisaoData={folhaDecisaoData}
                dataEtapa={dataEtapa}
                gameDetailsDTO={gameDetailsDTO}
              />
            ),
          },
          {
            tabTitle: 'Meu Estoque',
            tabChild: <MyStockContent scale={scale} folhaDecisaoData={folhaDecisaoData} gameDetailsDTO={gameDetailsDTO} />,
          },
          { tabTitle: 'Relatórios', tabChild: <ReportContainer /> },
        ]}
      />
    </Box>
  );
}

export default MidContainer;
