import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Box1 from '../../Atomos/Box';
import InputWithTitle from '../../Moleculas/InputWithTitle';
import SelectWithTitle from '../../Moleculas/SelectWithTitle';
import { CheckboxWithLabel } from '../../Atomos/CheckBoxWithLabel';
import { HelpTooltip } from '../../Atomos/HelpTooltip';
import { DropdownSelectOption } from '../../Atomos/DropdownSelect';
import { scaleValue } from '../../../utils'; // Função scaleValue

export type RowInputProp = {
  key: string;
  value: string;
  defaultValue?: DropdownSelectOption;
  disabled?: boolean;
  helperText?: string;
  validator?: (value: string) => string | null;
};

export type Row = {
  valueInputOne?: RowInputProp | DropdownSelectOption[];
  valueInputTwo?: RowInputProp | DropdownSelectOption[];
  valueInputThree?: RowInputProp | DropdownSelectOption[];
  valueInputFour?: RowInputProp | DropdownSelectOption[];
};

export type ProductContainerProps = {
  rowOne: Row;
  rowTwo: Row;
  rowThree: Row;
  productName: string;
  subProductName: string;
  checkboxStatus?: boolean;
  isTimeOver?: boolean;
  onChange: (data: any, error: string | null) => void;
  scale?: number;
};

export default function ProductContainer({
  productName,
  subProductName,
  rowOne,
  rowTwo,
  rowThree,
  checkboxStatus,
  onChange,
  isTimeOver,
  scale,
}: ProductContainerProps) {
  scale = scale ?? 1;
  const values: any = {};
  const valueSelectOne = (rowOne.valueInputOne as DropdownSelectOption[])[0].defaultValue;
  const valueSelectTwo = (rowTwo.valueInputOne as DropdownSelectOption[])[0].defaultValue;
  const [isBuyBloqued, setIsBuyBloqued] = React.useState<boolean>(false);
  const [isSellBloqued, setIsSellBloqued] = React.useState<boolean>(false);

  const handleChangeInput = (key: string, value: string, error: string | null) => {
    values[key] = value;
    onChange(values, error);
  };

  const handleChangeSelect = (option: DropdownSelectOption) => {
    values[option.key ?? option.label] = option.value;
    onChange(values, null);
  };

  useEffect(() => {
    if (Number(valueSelectOne?.value) === 0) {
      setIsBuyBloqued(true);
    } else {
      if (isBuyBloqued) setIsBuyBloqued(false);
    }

    if (Number(valueSelectTwo?.value) === 0) {
      setIsSellBloqued(true);
    } else {
      if (isSellBloqued) setIsSellBloqued(false);
    }
  }, [isBuyBloqued, isSellBloqued, rowOne, rowTwo, valueSelectOne?.value, valueSelectTwo?.value]);

  return (
    <Box1
      display='flex'
      flexDirection='column'
      sx={{
        height: 'auto',
        padding: scaleValue(scale, '23px 40px'),
        width: '95%',
        boxSizing: 'border-box',
      }}
    >
      <Box>
        <Typography
          sx={{
            width: scaleValue(scale, '100%'),
            color: 'black',
            fontSize: scaleValue(scale, 36),
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          {productName}
        </Typography>
        <Typography
          sx={{
            width: scaleValue(scale, '100%'),
            color: 'rgba(0, 0, 0, 0.60)',
            fontSize: scaleValue(scale, 12),
            fontFamily: 'Poppins',
            fontWeight: '400',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          {subProductName}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' width='100%' gap={scaleValue(scale, '32px')} marginTop={scaleValue(scale, '12px')}>
        <Box display='flex' flexDirection='column' sx={{ width: '100%' }}>
          <Typography
            sx={{
              width: scaleValue(scale, '100%'),
              color: 'black',
              fontSize: scaleValue(scale, 22),
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Compras
          </Typography>
          <Box display='flex' sx={{ justifyContent: 'space-between', gap: '4px', maxWidth: '998px', flexFlow: 'wrap' }}>
            <SelectWithTitle
              title='Prazo de Pagamento'
              tooltipText='Selecione a opção de compra.'
              option={(rowOne.valueInputOne as DropdownSelectOption[])[0].defaultValue}
              options={rowOne.valueInputOne as DropdownSelectOption[]}
              onSelect={handleChangeSelect}
              disabled={isTimeOver}
              divStyle={{ width: '33%' }}
            />
            <InputWithTitle
              title='Preço de Custo ($)'
              validator={(rowOne.valueInputTwo as RowInputProp).validator}
              helperText={(rowOne.valueInputTwo as RowInputProp).helperText}
              disabled={(rowOne.valueInputTwo as RowInputProp).disabled}
              tooltipText='Valor disponibilizada automaticamente pelo sistema.'
              initialAdornment='$'
              onChange={(event, error) => handleChangeInput((rowOne.valueInputTwo as RowInputProp).key, event.target.value, error)}
              defaultValue={(rowOne.valueInputTwo as RowInputProp).value}
            />
            <InputWithTitle
              disabled={isBuyBloqued || (rowOne.valueInputThree as RowInputProp).disabled}
              onlyPositiveNumber
              onlyInteger
              title='Qtd. de Compra'
              validator={(rowOne.valueInputThree as RowInputProp).validator}
              helperText={(rowOne.valueInputThree as RowInputProp).helperText}
              tooltipText='Insira a quantidade a ser comprada do produto – máximo de 100% sobre a quantidade prevista no jornal.'
              onChange={(event, error) => handleChangeInput((rowOne.valueInputThree as RowInputProp).key, event.target.value, error)}
              defaultValue={(rowOne.valueInputThree as RowInputProp).value}
            />
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' sx={{ width: '100%' }}>
          <Typography
            sx={{
              width: scaleValue(scale, '100%'),
              color: 'black',
              fontSize: scaleValue(scale, 22),
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Venda
          </Typography>
          <Box display='flex' gap={scaleValue(scale, '4px')} sx={{ margin: scaleValue(scale, '12px 0px') }}>
            <CheckboxWithLabel
              label='Com Entradas'
              checked={checkboxStatus}
              onChange={(event) => {
                const productKey = productName.split(' ').reverse()[0];
                const key = `vendas.${productKey}.com_entrada`;
                values[key] = event.target.checked;
                onChange(values, null);
              }}
              checkboxStyle={{ backgroundColor: '#EEE' }}
              textStyle={{
                color: 'rgba(0, 0, 0, 0.50)',
                fontSize: scaleValue(scale, '15px'),
                fontFamily: 'Poppins',
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word',
              }}
            />
            <HelpTooltip tooltipText='Selecione a opção de venda (com ou sem entrada).' size={12} />
          </Box>
          <Box display='flex' sx={{ justifyContent: 'space-between', gap: '4px', maxWidth: '998px', flexFlow: 'wrap' }}>
            <SelectWithTitle
              title='Condição de Recebimento'
              tooltipText='Selecione a condição de recebimento principal pela venda dos produtos.'
              option={(rowTwo.valueInputOne as DropdownSelectOption[])[0].defaultValue}
              options={rowTwo.valueInputOne as DropdownSelectOption[]}
              onSelect={handleChangeSelect}
              disabled={isTimeOver}
              divStyle={{ width: '33%' }}
            />
            <InputWithTitle
              disabled={isSellBloqued || (rowTwo.valueInputTwo as RowInputProp).disabled}
              title='Preço de Venda ($)'
              validator={(rowTwo.valueInputTwo as RowInputProp).validator}
              helperText={(rowTwo.valueInputTwo as RowInputProp).helperText}
              tooltipText='Insira o preço de venda do produto.'
              initialAdornment='$'
              onChange={(event, error) => handleChangeInput((rowTwo.valueInputTwo as RowInputProp).key, event.target.value, error)}
              defaultValue={(rowTwo.valueInputTwo as RowInputProp).value}
            />
            <InputWithTitle
              disabled={isSellBloqued || (rowTwo.valueInputThree as RowInputProp).disabled}
              title='Taxa de Financiamento (%)'
              validator={(rowTwo.valueInputThree as RowInputProp).validator}
              helperText={(rowTwo.valueInputThree as RowInputProp).helperText}
              tooltipText='Insira uma taxa de financiamento para vendas à prazo.'
              onChange={(event, error) => handleChangeInput((rowTwo.valueInputThree as RowInputProp).key, event.target.value, error)}
              defaultValue={(rowTwo.valueInputThree as RowInputProp).value}
            />
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' sx={{ width: '100%' }}>
          <Typography
            sx={{
              width: scaleValue(scale, '100%'),
              color: 'black',
              fontSize: scaleValue(scale, 22),
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Marketing
          </Typography>
          <Box display='flex' sx={{ justifyContent: 'space-between', gap: '4px', maxWidth: '998px', flexFlow: 'wrap' }}>
            <SelectWithTitle
              divStyle={{ width: '21%' }}
              title='Impresso'
              tooltipText='Insira aqui o número de campanhas de impresso que você deseja investir para divulgar o produto.'
              option={(rowThree.valueInputOne as DropdownSelectOption[])[0].defaultValue}
              options={rowThree.valueInputOne as DropdownSelectOption[]}
              disabled={isTimeOver}
              onSelect={handleChangeSelect}
            />
            <SelectWithTitle
              divStyle={{ width: '21%' }}
              title='Mídias Sociais'
              tooltipText='Insira aqui o número de campanhas de mídias sociais que você deseja investir para divulgar o produto.'
              option={(rowThree.valueInputTwo as DropdownSelectOption[])[0].defaultValue}
              options={rowThree.valueInputTwo as DropdownSelectOption[]}
              disabled={isTimeOver}
              onSelect={handleChangeSelect}
            />
            <SelectWithTitle
              divStyle={{ width: '21%' }}
              title='Rádio'
              tooltipText='Insira aqui o número de campanhas de rádio que você deseja investir para divulgar o produto.'
              option={(rowThree.valueInputThree as DropdownSelectOption[])[0].defaultValue}
              options={rowThree.valueInputThree as DropdownSelectOption[]}
              disabled={isTimeOver}
              onSelect={handleChangeSelect}
            />
            <SelectWithTitle
              divStyle={{ width: '21%' }}
              title='Tv'
              tooltipText='Insira aqui o número de campanhas de tv que você deseja investir para divulgar o produto.'
              option={(rowThree.valueInputFour as DropdownSelectOption[])[0].defaultValue}
              options={rowThree.valueInputFour as DropdownSelectOption[]}
              disabled={isTimeOver}
              onSelect={handleChangeSelect}
            />
          </Box>
        </Box>
      </Box>
    </Box1>
  );
}
