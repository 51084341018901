import { Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React from 'react';
import Box1 from '../../Atomos/Box';
import CircleAvatarImage from '../../Atomos/CirlceAvatarImage';
import { ReactComponent as UserIcon } from './UserIcon.svg';

const scenarioCircleColorOptions = {
  green: '🟢',
  red: '🔴',
  yellow: '🟡',
  blue: '🔵',
};

export type UserProfileBoxProps = {
  width?: string | number | undefined;
  height?: string | number | undefined;
  name: string;
  company: string;
  scenario: string;
  src?: string;
  scenarioCircleColor: 'green' | 'red' | 'yellow' | 'blue';
  boxStyle?: React.CSSProperties;
};

export default function UserProfileBox({
  width,
  height,
  name,
  company,
  scenario,
  src,
  scenarioCircleColor,
  boxStyle,
}: UserProfileBoxProps) {
  const MAX_NAME_LENGTH = 16;
  const MAX_COMPANY_LENGTH = 20;
  const MAX_SCENARIO_LENGTH = 15;

  return (
    <Box1
      display='flex'
      flexDirection='column'
      sx={{
        padding: '8px 24px 12px 14px',
        justifyContent: 'flex-start',
        alignItems: 'normal',
        height: height ?? '100px',
        width: width ?? '264px',
        ...boxStyle,
      }}
    >
      <Box display='flex' width='100%' justifyContent='space-between'>
        <Box display='flex'>
          <div style={{ marginRight: '8px' }}>
            {src ? (
              <CircleAvatarImage src={src} width='35px' height='35px' />
            ) : (
              <AccountCircleIcon style={{ width: '35px', height: '35px' }} />
            )}
          </div>
          <Typography
            style={{
              alignContent: 'center',
              color: 'black',
              fontSize: 18,
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {name.substring(0, MAX_NAME_LENGTH)}
          </Typography>
        </Box>
        <UserIcon style={{ alignSelf: 'center' }} />
      </Box>
      <Box display='flex'>
        <Typography
          style={{
            color: 'black',
            fontSize: 12,
            fontFamily: 'Poppins',
            fontWeight: '500',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
            marginRight: '4px',
          }}
        >
          Empresa:{' '}
        </Typography>
        <Typography
          style={{ color: 'black', fontSize: 12, fontFamily: 'Poppins', fontWeight: '300', letterSpacing: 0.15, wordWrap: 'break-word' }}
        >
          {company.substring(0, MAX_COMPANY_LENGTH)}
        </Typography>
      </Box>
      {scenario && (
        <Box display='flex'>
          <Typography
            style={{
              color: 'black',
              fontSize: 12,
              fontFamily: 'Poppins',
              fontWeight: '500',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
              marginRight: '4px',
            }}
          >
            Cenário:{' '}
          </Typography>
          <Typography
            style={{ color: 'black', fontSize: 12, fontFamily: 'Poppins', fontWeight: '300', letterSpacing: 0.15, wordWrap: 'break-word' }}
          >
            {scenario.substring(0, MAX_SCENARIO_LENGTH)} {scenarioCircleColorOptions[scenarioCircleColor]}
          </Typography>
        </Box>
      )}
    </Box1>
  );
}
