/* eslint-disable no-lonely-if */
import { Box, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Input1 from '../../Atomos/Input1';
import { HelpTooltip } from '../../Atomos/HelpTooltip';
import { Formarters } from '../../../utils';

export type InputWithTitleProps = {
  title: string;
  tooltipText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, error: string | null) => void;
  initialAdornment?: string;
  defaultValue?: string;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  validator?: (value: string) => string | null; // Função de validação
  helperText?: string; // Texto de ajuda
  onlyPositiveNumber?: boolean;
  onlyInteger?: boolean;
};

const textStyle: React.CSSProperties = {
  color: 'rgba(0, 0, 0, 0.60)',
  fontFamily: 'Poppins',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '28px',
  letterSpacing: '0.15px',
};

export default function InputWithTitle({
  title,
  tooltipText,
  initialAdornment,
  defaultValue,
  inputStyle,
  disabled,
  onChange,
  validator,
  onlyInteger,
  helperText,
  onlyPositiveNumber = true,
}: InputWithTitleProps) {
  const [error, setError] = useState<string | null>(null); // Estado para o erro
  const [actualValue, setActualValue] = useState<string | undefined>(); // Estado para o valor atual

  useEffect(() => {
    const isError = validator?.(actualValue || defaultValue || '');
    if (isError) setError(isError);
    else if (error !== null) setError(null);
  }, []);

  useEffect(() => {
    if (disabled) setActualValue(undefined);
    else setActualValue(defaultValue);

    const isError = validator?.(defaultValue || '');
    if (isError) setError(isError);
    else if (error !== null) setError(null);
  }, [disabled]);

  const maskMoneyUnlimited = (value: any) => {
    // Remove todos os caracteres que não são dígitos
    value = value.replace(/\D/g, '');

    // Se o valor for menor que 3 dígitos, ajuste para ter pelo menos 3
    // while (value.length < 3) {
    //   value = `0${value}`;
    // }

    // Adiciona a vírgula para os decimais
    value = value.replace(/(\d{2})$/, ',$1');

    // Adiciona o ponto para cada grupo de milhares
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    // Adiciona o símbolo de moeda
    return value;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace('.', ',');
    let isError = false;
    let validationError: string | null = null;
    const normalizedValue = Formarters.parseNumberWithComma(value);

    // Executa a função de validação, se houver
    if (validator) {
      validationError = validator(value);
      setError(validationError); // Atualiza o estado do erro
    }

    if (onlyInteger && value !== '') {
      if (!normalizedValue && normalizedValue !== 0) {
        isError = true;
      }
      if (!isError) {
        value = parseInt(value).toString();
      }
    }

    if (onlyPositiveNumber) {
      let canUpdateValue = true;
      if (!(normalizedValue >= 0)) canUpdateValue = false;
      if (isError) canUpdateValue = false;
      if (value === 'NaN') canUpdateValue = false;

      if (canUpdateValue || value === '') {
        if (onlyInteger) setActualValue(value);
        else setActualValue(maskMoneyUnlimited(value));
        onChange?.(event, validationError);
      }
    } else {
      if (onlyInteger) setActualValue(value);
      else setActualValue(maskMoneyUnlimited(value));
      onChange?.(event, validationError);
    }
  };

  return (
    <div>
      <Box display='flex' gap='4px'>
        <Typography style={{ ...textStyle }}>{title}</Typography>
        <HelpTooltip tooltipText={tooltipText} size={12} />
      </Box>
      <Input1
        sx={{ marginTop: '4px', ...inputStyle }}
        onChange={handleInputChange} // Substitui o onChange original
        defaultValue={defaultValue}
        value={actualValue}
        disabled={disabled}
        error={!!error} // Se houver erro, ativa o estado de erro no Input1
        helperText={error || helperText} // Mostra o erro ou o texto de ajuda
        isControlledComponent
        InputProps={{
          startAdornment: <InputAdornment position='start'>{initialAdornment}</InputAdornment>,
        }}
      />
    </div>
  );
}
