import { Box, BoxProps } from '@mui/material';
import React from 'react';

export type IBox1Props = {
  children?: React.ReactNode;
  sx?: React.CSSProperties;
};

export type Box1Props = BoxProps & IBox1Props;

const style: React.CSSProperties = {
  display: 'flex',
  height: '126px',
  padding: '4px 97px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '8px',
  borderRadius: '15px',
  border: '1.5px solid #E0E0E0',
};

export default function Box1({ sx, children, ...props }: Box1Props) {
  return (
    <Box sx={{ ...style, ...sx }} {...props}>
      {children}
    </Box>
  );
}
