import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

export type CheckboxWithLabelProps = {
  label: string;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
};

const checkboxCss: React.CSSProperties = {
  width: '26px',
  height: '26px',
  borderRadius: '4px',
  border: '1px solid #E0E0E0',
  background: '#FFF',
  color: 'transparent',
  marginRight: '8px',
};

const textCss: React.CSSProperties = {
  color: '#000',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.15px',
};

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({ label, checked, onChange, checkboxStyle, textStyle }) => {
  const [isChecked, setIsChecked] = React.useState(checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    onChange(event);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={handleChange}
          sx={{ ...checkboxCss, ...checkboxStyle, '&.Mui-checked': { color: 'gray' } }}
        />
      }
      label={
        <Typography variant='body1' sx={{ ...textCss, ...textStyle }}>
          {label}
        </Typography>
      }
      sx={{ margin: '0px' }}
    />
  );
};
