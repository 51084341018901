import { Box, Grid, Typography, Card } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import TvIcon from '@mui/icons-material/Tv';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Formarters } from '../../../../lib/formaters';
import { IndicatorsDTO } from '../../../../dto';

export type MarketingSectionProps = {
  dataIndicator: IndicatorsDTO | undefined;
};

export default function MarketingSection({ dataIndicator }: MarketingSectionProps): JSX.Element {
  function getIconByKey(key: string) {
    if (key === 'radio') {
      return <KeyboardVoiceIcon sx={{ fontSize: '28px', marginRight: '5px', marginBottom: '5px' }} />;
    } else if (key === 'tv') {
      return <TvIcon sx={{ fontSize: '28px', marginRight: '5px', marginBottom: '3px' }} />;
    } else if (key === 'midias_sociais') {
      return <SmartphoneIcon sx={{ fontSize: '28px', marginRight: '5px', marginBottom: '3px' }} />;
    } else if (key === 'impressos') {
      return <ReceiptLongOutlinedIcon sx={{ fontSize: '28px', marginRight: '5px', marginBottom: '3px' }} />;
    } else {
      return null;
    }
  }

  return (
    <Box width='85%' mt={{ xs: 6, sm: 6, md: 4, lg: 4 }}>
      <Typography color='#00AB4A' fontSize='clamp(1.5rem, 2.5vw, 1.8rem)' fontFamily='Poppins' fontWeight={700}>
        Custo das campanhas de marketing
      </Typography>
      <Grid container spacing={2} pt={3}>
        {dataIndicator &&
          Object.entries(dataIndicator.campanhas_marketing_custo).map(([key, value]) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
              <Card
                sx={{
                  width: '100%',
                  backgroundColor: '#FAFAFA',
                  borderRadius: '8px',
                  variant: 'outlined',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
                variant='outlined'
              >
                <Box sx={{ padding: '35px 32px', display: 'grid' }}>
                  <Box display='flex' alignItems='center' pb={0.25}>
                    {getIconByKey(key)}
                    <Typography lineHeight='25px' fontSize='clamp(1.25rem, 2.5vw, 1.45rem)' fontFamily='Poppins' fontWeight={600}>
                      {Formarters.translateKeyIndicators(key)}
                    </Typography>
                  </Box>
                  <Typography fontSize='clamp(1.35rem, 2.5vw, 1.75rem)' fontFamily='Poppins' fontWeight={600} color='#666666'>
                    ${Formarters.formatHelperText(value)}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
